// src/constants/plans.js
export const BASIC_PLAN = '啟蒙計劃';
export const ADVANCED_PLAN = '進階計劃';

export const PLAN_DETAILS = {
  'prod_QpoufCzzqhAHNH': BASIC_PLAN,
  'prod_QzRt0ZZ6zdeXWp': ADVANCED_PLAN,
};

// Define feature permissions per plan
export const FEATURE_PERMISSIONS = {
  none: [''], // Logged In (no plan)
  [BASIC_PLAN]: ['voiceLanguage', 'animation', 'blur'],
  [ADVANCED_PLAN]: ['voiceLanguage', 'animation', 'blur', 'hotspot'],
};
