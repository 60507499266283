<template>
  <div class="read-strokes-container">
    <svg :id="strokeTargetId" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size">
      <!-- Horizontal lines -->
      <line x1="0" :y1="size/3" :x2="size" :y2="size/3" stroke="#DDD" />
      <line x1="0" :y1="size*2/3" :x2="size" :y2="size*2/3" stroke="#DDD" />
      <!-- Vertical lines -->
      <line :x1="size/3" y1="0" :x2="size/3" :y2="size" stroke="#DDD" />
      <line :x1="size*2/3" y1="0" :x2="size*2/3" :y2="size" stroke="#DDD" />
    </svg>



    <!-- Cartoon-like Button -->
    <button @click="animateCharacter" class="
      bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-8 rounded-full shadow-lg transition-transform transform hover:scale-110 active:scale-95 focus:outline-none">
      <!-- Play Icon SVG -->
      <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" class="w-4 h-4">
        <path d="M3 22V2l18 10-18 10z"/>
      </svg>
    </button>


  </div>
</template>

<script setup>
import { onMounted, ref, defineProps, watch, nextTick  } from 'vue';
import HanziWriter from 'hanzi-writer';

// Props
const props = defineProps({
  word: {
    type: String,
    required: true,
  },
  idSuffix: {
    type: String,
    required: true,
  },
});

// Variable for size
const size = 200;  // Change this to control the size

// Dynamic ID for the SVG container
const strokeTargetId = `stroke-target-${props.idSuffix}`;

const writer = ref(null);

onMounted(() => {
  writer.value = HanziWriter.create(strokeTargetId, props.word, {
    width: size,
    height: size,
    padding: 10,
    strokeAnimationSpeed: 1.5, // 5x normal speed
    delayBetweenStrokes: 500, // milliseconds
    strokeColor: '#444',
    radicalColor: '#000',
    delayBetweenLoops: 2000,
  });
});

const setCharacter = () => {
  nextTick(() => {
    writer.value.setCharacter(props.word);
    writer.value.quiz();
  });
};

function animateCharacter() {
  if (writer.value) {
    writer.value.animateCharacter();
  }
}

// Watch for changes in props
watch([() => props.word, () => props.idSuffix], () => {
  setCharacter();
}, { immediate: true });
</script>

<style scoped>
.read-strokes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
