<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <!-- Content Section -->
    <div class="flex-grow h-auto">
      <router-view />
    </div>

    <!-- Footer Section -->
    <!-- <footer class="bg-gray-200 text-gray-600 text-center text-xs p-2 mt-auto w-full">
      <div class="container mx-auto">
        © 2024 小海豚中文工作室. All rights reserved.
      </div>
    </footer> -->
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>
