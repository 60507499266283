<template>
  <!-- Use the NavBar Component with title and handleLogout -->
  <NavBar @back-clicked="goHome" @logout-clicked="logout" title="管理帳戶" />

  <section class="bg-gray-50 min-h-screen scroll-smooth">
    <div class="py-12 px-6 mx-auto max-w-screen-lg lg:py-16 lg:px-8">
      <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
        <h2 class="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          我的帳戶
        </h2>
        <div class="mb-5 font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <div>歡迎 <span class="font-bold italic" v-if="currentUser">{{ currentUser.displayName || currentUser.email
              }}</span></div>
          <div>請在此管理您的設置和訂閱。</div>
        </div>
      </div>

      <!-- Authentication Step -->
      <div v-if="!authorized" class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-8">
        <div class="text-center">
          <p class="mb-4">請回答以下數學問題以進入設置頁面：</p>
          <p class="text-xl font-bold mb-4">{{ question.a }} x {{ question.b }} = ?</p>
          <div class="flex justify-center items-center mb-4">
            <input v-model="userAnswer" @keyup.enter="checkAnswer" type="number" class="p-2 border rounded md:w-3/4"
              placeholder="輸入您的答案" />
            <button @click="checkAnswer"
              class="ml-2 px-3 py-2 bg-blue-500 text-white rounded md:w-1/4 max-w-20 min-w-16">提交</button>
          </div>
          <div v-if="errorMessage" class="text-red-500">{{ errorMessage }}</div>
        </div>
      </div>

      <!-- Learning Settings Section -->
      <div v-else>
        <!-- Subscription Card -->
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-8">
          <PriceSelection v-if="!subscription" />
          <SubscribedAccount v-else :subscription="subscription" ref="subscribedSection" />
        </div>

        <!-- Learning Settings Section -->
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 mb-8">
          <h3 class="text-2xl font-bold mb-6 text-gray-900 dark:text-white">學習設定</h3>

          <!-- Cards Container -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">

            <!-- 預設語音設定 (Voice Settings) Card -->
            <div class="bg-gray-50 dark:bg-gray-700 rounded-2xl shadow p-6 relative">
              <!-- Main Content -->
              <h4 class="text-xl font-semibold mb-6 text-gray-900 dark:text-white">預設語音設定</h4>

              <!-- Radio Buttons for Voice Language -->
              <div class="flex flex-row items-center mb-4 space-x-6">
                <!-- Cantonese Radio Button -->
                <label class="flex items-center">
                  <input type="radio" id="cantonese" value="cantonese" v-model="voiceLanguage"
                    @change="updateVoiceLanguage(voiceLanguage)"
                    class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    :disabled="!canSetFeature('voiceLanguage')" />
                  <span class="ml-2 text-gray-700 dark:text-gray-300">粵語</span>
                </label>

                <!-- Mandarin Radio Button -->
                <label class="flex items-center">
                  <input type="radio" id="mandarin" value="mandarin" v-model="voiceLanguage"
                    @change="updateVoiceLanguage(voiceLanguage)"
                    class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    :disabled="!canSetFeature('voiceLanguage')" />
                  <span class="ml-2 text-gray-700 dark:text-gray-300">國語</span>
                </label>
              </div>

              <!-- Display Error Message if Any -->
              <div v-if="languageErrorMessage" class="text-red-500 mt-2">{{ languageErrorMessage }}</div>

              <!-- Restricted Overlay -->
              <div v-if="!canSetFeature('voiceLanguage')"
                class="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center text-white rounded-2xl">
                <Lock class="h-10 w-10 mb-4" />

                <!-- Subscribe Button -->
                <button @click="goToSubscribe"
                  class="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition">
                  訂閱解鎖功能
                </button>
              </div>
            </div>


            <!-- 動畫功能 (Animation Features) Card -->
            <div class="bg-gray-50 dark:bg-gray-700 rounded-2xl shadow p-6 relative">
              <!-- Main Content -->
              <h4 class="text-xl font-semibold mb-2 text-gray-900 dark:text-white">動畫功能</h4>
              <p class="text-sm text-gray-600 mb-4">加強專注，趣味學習</p>
              <div class="flex items-center">
                <!-- Toggle Switch -->
                <label class="relative inline-flex cursor-pointer items-center space-x-2">
                  <span class="text-gray-700 mr-2">關</span>
                  <input id="animation-switch" type="checkbox" class="peer sr-only" v-model="animationEnabled"
                    @change="toggleAnimation" :disabled="!canSetFeature('animation')" />
                  <div class="
                    peer h-4 w-11 rounded-full border bg-slate-200 
                    after:absolute 
                    after:top-0
                    after:left-7
                    after:h-6 
                    after:w-6 
                    after:rounded-full 
                    after:border 
                    after:border-gray-300 
                    after:bg-white 
                    after:transition-all 
                    after:content-[''] 
                    peer-checked:bg-green-300 
                    peer-checked:after:translate-x-full 
                    peer-focus:ring-green-300"></div>
                  <span class="text-gray-700 ml-2">開</span>
                </label>
                <!-- Right side: Image Placeholder -->
                <div class="ml-auto pl-4">
                  <img src="/animate.gif" alt="animate feature sample" class="w-32 h-32">
                </div>
              </div>

              <!-- Restricted Overlay -->
              <div v-if="!canSetFeature('animation')"
                class="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center text-white rounded-2xl">
                <Lock class="h-10 w-10 mb-4" />

                <!-- Subscribe Button -->
                <button @click="goToSubscribe"
                  class="px-4 py-2 border-4 border-white text-white rounded transition">
                  訂閱解鎖功能
                </button>
              </div>
            </div>


            <!-- 模糊功能 (Blur Features) Card -->
            <div class="bg-gray-50 dark:bg-gray-700 rounded-2xl shadow p-6 relative">
              <!-- Main Content -->
              <h4 class="text-xl font-semibold mb-2 text-gray-900 dark:text-white">模糊功能</h4>
              <p class="text-sm text-gray-600 mb-4">訓練記憶，強化大腦迴路</p>
              <div class="flex items-center">
                <!-- Toggle Switch -->
                <label class="relative inline-flex cursor-pointer items-center space-x-2">
                  <span class="text-gray-700 mr-2">關</span>
                  <input id="blur-switch" type="checkbox" class="peer sr-only" v-model="blurEnabled"
                    @change="toggleBlur" :disabled="!canSetFeature('blur')" />
                  <div class="
                    peer h-4 w-11 rounded-full border bg-slate-200 
                    after:absolute 
                    after:top-0
                    after:left-7
                    after:h-6 
                    after:w-6 
                    after:rounded-full 
                    after:border 
                    after:border-gray-300 
                    after:bg-white 
                    after:transition-all 
                    after:content-[''] 
                    peer-checked:bg-green-300 
                    peer-checked:after:translate-x-full 
                    peer-focus:ring-green-300"></div>
                  <span class="text-gray-700 ml-2">開</span>
                </label>
                <!-- Right side: Image Placeholder -->
                <div class="ml-auto pl-4">
                  <img src="/blur.gif" alt="blur feature sample" class="w-32 h-32">
                </div>
              </div>

              <!-- Restricted Overlay -->
              <div v-if="!canSetFeature('blur')"
                class="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center text-white rounded-2xl">
                <Lock class="h-10 w-10 mb-4" />

                <!-- Subscribe Button -->
                <button @click="goToSubscribe"
                  class="px-4 py-2 border-4 border-white text-white rounded transition">
                  訂閱解鎖功能
                </button>
              </div>
            </div>


            <!-- 熱點功能 (Hotspot Features) Card -->
            <div class="bg-gray-50 dark:bg-gray-700 rounded-2xl shadow p-6 relative">
              <!-- Main Content -->
              <h4 class="text-xl font-semibold mb-2 text-gray-900 dark:text-white">熱點功能</h4>
              <p class="text-sm text-gray-600 mb-4">組件字詞，了解中文字模式</p>
              <div class="flex items-center">
                <!-- Toggle Switch -->
                <label class="relative inline-flex cursor-pointer items-center space-x-2">
                  <span class="text-gray-700 mr-2">關</span>
                  <input id="hotspot-switch" type="checkbox" class="peer sr-only" v-model="hotspotEnabled"
                    @change="toggleHotspot" :disabled="!canSetFeature('hotspot')" />
                  <div class="
                      peer h-4 w-11 rounded-full border bg-slate-200 
                      after:absolute 
                      after:top-0
                      after:left-7
                      after:h-6 
                      after:w-6 
                      after:rounded-full 
                      after:border 
                      after:border-gray-300 
                      after:bg-white 
                      after:transition-all 
                      after:content-[''] 
                      peer-checked:bg-green-300 
                      peer-checked:after:translate-x-full 
                      peer-focus:ring-green-300
                  "></div>
                  <span class="text-gray-700 ml-2">開</span>
                </label>
                <!-- Image Placeholder -->
                <div class="ml-auto pl-4">
                  <img src="/hotspot.gif" alt="hotspot feature sample" class="w-32 h-32">
                </div>
              </div>

              <!-- Restricted Overlay -->
              <div v-if="!canSetFeature('hotspot')"
                class="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center text-white rounded-2xl">
                <Lock class="h-10 w-10 mb-4" />

                <!-- Subscribe Button -->
                <button @click="goToSubscribe"
                  class="px-4 py-2 border-4 border-white text-white rounded transition">
                  訂閱解鎖功能
                </button>
              </div>
            </div>

          </div>
        </div>

        <!-- Toast Notification -->
        <transition name="fade">
          <div v-if="showToast"
            class="fixed bottom-5 right-5 bg-green-500 text-white px-4 py-2 rounded shadow-lg flex items-center cursor-pointer"
            @click="showToast = false">
            <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <span>{{ toastMessage }}</span>
          </div>
        </transition>
      </div>

    </div>
  </section>
</template>
<script setup>
import { ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { signOut } from 'firebase/auth';
import PriceSelection from './PriceSelection.vue';
import SubscribedAccount from './SubscribedAccount.vue';
import NavBar from '../components/NavBar.vue';
import { getCurrentUser, db, firebaseAuth } from '@/firebase/firebase';
import {
  getFirestore,
  getDocs,
  where,
  query,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import {
  updateVoiceLanguageCache,
  updateAnimationEnabledCache,
  updateBlurEnabledCache,
  updateHotspotEnabledCache,
} from '@/utils/CacheUtil.js';
import { BASIC_PLAN, ADVANCED_PLAN, PLAN_DETAILS, FEATURE_PERMISSIONS } from '@/constants/plan';
import { getSubscribedPlan } from '@/utils/CommonUtil';
import Lock from '@/assets/icon/Lock.vue';

const router = useRouter();
const route = useRoute();

const currentUser = ref(null);
const subscription = ref(null);

// Reactive variables for authentication
const authorized = ref(false);
const question = ref({ a: 0, b: 0 });
const userAnswer = ref('');
const errorMessage = ref(''); // For displaying error messages

// User settings
const voiceLanguage = ref('cantonese'); // Default to Cantonese
const animationEnabled = ref(true);
const blurEnabled = ref(false);
const hotspotEnabled = ref(false);

const languageErrorMessage = ref(''); // For displaying error messages related to language setting

// Toast related reactive variables
const showToast = ref(false);
const toastMessage = ref('');

// Computed property to get the user's subscribed plan
const subscribedPlan = computed(() => getSubscribedPlan(subscription.value));

// Computed property to get allowed features based on the plan
const allowedFeatures = computed(() => {
  return FEATURE_PERMISSIONS[subscribedPlan.value] || [];
});

/**
 * Checks if the user can set a particular feature based on their subscription.
 * @param {string} feature - The feature name (e.g., 'animation', 'blur', 'hotspot', ''voiceLanguage'').
 * @returns {boolean} - True if the user can set the feature, else false.
 */
const canSetFeature = (feature) => {
  return allowedFeatures.value.includes(feature);
};

// Reference to the SubscribedAccount component
const subscribedSection = ref(null);

// Define difficult multiplication pairs
const difficultPairs = [
  [6, 7], [6, 8], [6, 9],
  [7, 8], [7, 9],
  [8, 9],
];

/**
 * Checks if the given pair is considered difficult.
 * @param {number} a - First multiplier.
 * @param {number} b - Second multiplier.
 * @returns {boolean} - True if the pair is difficult, else false.
 */
function isDifficult(a, b) {
  return difficultPairs.some(pair =>
    (pair[0] === a && pair[1] === b) ||
    (pair[0] === b && pair[1] === a)
  );
}

/**
 * Generates a new multiplication question ensuring it's not difficult.
 */
function generateQuestion() {
  const MAX_ATTEMPTS = 10;
  let attempts = 0;
  let a, b;

  do {
    a = Math.floor(Math.random() * 9) + 2; // 2 to 10
    b = Math.floor(Math.random() * 9) + 2; // 2 to 10
    attempts++;
    // Prevent infinite loop by limiting attempts
    if (attempts > MAX_ATTEMPTS) {
      break;
    }
  } while (isDifficult(a, b));

  question.value.a = a;
  question.value.b = b;
  userAnswer.value = '';
  errorMessage.value = ''; // Clear any previous error message
}

// Initialize the first question
generateQuestion();

/**
 * Checks if the user's answer is correct.
 * If correct, authorize access. If incorrect, show error and generate a new question.
 */
function checkAnswer() {
  const correctAnswer = question.value.a * question.value.b;
  if (parseInt(userAnswer.value) === correctAnswer) {
    authorized.value = true;
  } else {
    errorMessage.value = '答案不正確，請再試一次。';
    generateQuestion(); // Generate a new question
  }
}

// Clear error message when user starts typing
watch(userAnswer, () => {
  if (errorMessage.value) {
    errorMessage.value = '';
  }
});

/**
 * Initializes user data and subscription information.
 */
async function initialize() {
  currentUser.value = await getCurrentUser();
  await fetchSubscription();
  await fetchUserSettings(); // Fetch all user settings during initialization
}
initialize();

/**
 * Logs out the current user and redirects to the login page.
 */
async function logout() {
  try {
    await signOut(firebaseAuth);
    router.push('/login');
  } catch (error) {
    console.error('登出失敗', error);
  }
}

/**
 * Fetches the user's settings from Firestore.
 * Defaults are set if not found or not permitted by the plan.
 */
const fetchUserSettings = async () => {
  if (!currentUser.value) {
    // Set defaults if no user
    voiceLanguage.value = 'cantonese'; // Default to 'cantonese'
    animationEnabled.value = true; // Default to true
    blurEnabled.value = false;
    hotspotEnabled.value = false;
    return;
  }

  const settingsDocRef = doc(db, 'customers', currentUser.value.uid, 'settings', 'preference');
  const settingsDocSnap = await getDoc(settingsDocRef);
  if (settingsDocSnap.exists()) {
    const data = settingsDocSnap.data();
    voiceLanguage.value = data.voiceLanguage || 'cantonese'; // Default to 'cantonese'
    animationEnabled.value = data.animationEnabled !== undefined ? data.animationEnabled : true; // Default to true
    blurEnabled.value = data.blurEnabled !== undefined ? data.blurEnabled : false;
    hotspotEnabled.value = data.hotspotEnabled !== undefined ? data.hotspotEnabled : false;
  } else {
    // Set defaults if document does not exist
    voiceLanguage.value = 'cantonese'; // Default to 'cantonese'
    animationEnabled.value = true; // Default to true
    blurEnabled.value = false;
    hotspotEnabled.value = false;
  }

  // Enforce feature permissions based on the subscribed plan
  if (!canSetFeature('voiceLanguage')) {
    voiceLanguage.value = 'cantonese'; // Always 'cantonese' when user has no plan
  }

  // Enforce feature permissions based on the subscribed plan
  if (!canSetFeature('animation')) {
    animationEnabled.value = true; // Always true when user has no plan
  }
  if (!canSetFeature('blur')) blurEnabled.value = false;
  if (!canSetFeature('hotspot')) hotspotEnabled.value = false;

  // Update Firestore to reflect enforced permissions
  await updateUserSettings();
};


/**
 * Updates the user's settings in Firestore based on enforced permissions.
 */
const updateUserSettings = async () => {
  if (!currentUser.value) return;

  const settingsDocRef = doc(db, 'customers', currentUser.value.uid, 'settings', 'preference');
  const updatedData = {
    voiceLanguage: canSetFeature('voiceLanguage') ? voiceLanguage.value : 'cantonese', // Set to 'cantonese' if no permission
    animationEnabled: canSetFeature('animation') ? animationEnabled.value : true, // From previous setup
    blurEnabled: canSetFeature('blur') ? blurEnabled.value : false,
    hotspotEnabled: canSetFeature('hotspot') ? hotspotEnabled.value : false,
  };

  try {
    await setDoc(settingsDocRef, updatedData, { merge: true });
  } catch (error) {
    console.error('Error enforcing feature permissions:', error);
  }
};



/**
 * Updates the user's voice language preference in Firestore.
 * @param {string} newLanguage - The new language selected by the user ('cantonese' or 'mandarin').
 */
 const updateVoiceLanguage = async (newLanguage) => {
  if (!currentUser.value) {
    console.error('No user is logged in.');
    return;
  }

  if (!canSetFeature('voiceLanguage')) {
    // Do not allow updating if the user cannot set this feature
    voiceLanguage.value = 'cantonese'; // Reset to default
    return;
  }

  const settingsDocRef = doc(db, 'customers', currentUser.value.uid, 'settings', 'preference');

  try {
    await setDoc(settingsDocRef, { voiceLanguage: newLanguage }, { merge: true });
    voiceLanguage.value = newLanguage;
    languageErrorMessage.value = ''; // Clear any previous error messages

    updateVoiceLanguageCache(newLanguage); // Update cache

    // Show success toast
    toastMessage.value = '語音設定已成功更新！'; // "Voice settings updated successfully!"
    showToast.value = true;

    // Automatically hide the toast after 3 seconds
    setTimeout(() => {
      showToast.value = false;
    }, 3000);

  } catch (error) {
    console.error('Error updating voice language:', error);
    languageErrorMessage.value = '無法更新語音設定，請稍後再試。'; // "Unable to update voice settings, please try again later."
  }
};


/**
 * Updates the user's animation setting in Firestore.
 * @param {boolean} newValue - The new value of the animation setting.
 */
const updateAnimationEnabled = async (newValue) => {
  if (!currentUser.value) {
    console.error('No user is logged in.');
    return;
  }
  const settingsDocRef = doc(db, 'customers', currentUser.value.uid, 'settings', 'preference');

  try {
    await setDoc(settingsDocRef, { animationEnabled: newValue }, { merge: true });
    animationEnabled.value = newValue;

    updateAnimationEnabledCache(newValue);

    // Show success toast
    toastMessage.value = '動畫功能設定已成功更新！'; // "Animation setting updated successfully!"
    showToast.value = true;

    // Automatically hide the toast after 3 seconds
    setTimeout(() => {
      showToast.value = false;
    }, 3000);
  } catch (error) {
    console.error('Error updating animation setting:', error);
    // Handle error message if needed
  }
};

/**
 * Updates the user's blur setting in Firestore.
 * @param {boolean} newValue - The new value of the blur setting.
 */
const updateBlurEnabled = async (newValue) => {
  if (!currentUser.value) {
    console.error('No user is logged in.');
    return;
  }
  const settingsDocRef = doc(db, 'customers', currentUser.value.uid, 'settings', 'preference');

  try {
    await setDoc(settingsDocRef, { blurEnabled: newValue }, { merge: true });
    blurEnabled.value = newValue;

    updateBlurEnabledCache(newValue);

    // Show success toast
    toastMessage.value = '模糊功能設定已成功更新！'; // "Blur setting updated successfully!"
    showToast.value = true;

    // Automatically hide the toast after 3 seconds
    setTimeout(() => {
      showToast.value = false;
    }, 3000);
  } catch (error) {
    console.error('Error updating blur setting:', error);
    // Handle error message if needed
  }
};

/**
 * Updates the user's hotspot setting in Firestore.
 * @param {boolean} newValue - The new value of the hotspot setting.
 */
const updateHotspotEnabled = async (newValue) => {
  if (!currentUser.value) {
    console.error('No user is logged in.');
    return;
  }
  const settingsDocRef = doc(db, 'customers', currentUser.value.uid, 'settings', 'preference');

  try {
    await setDoc(settingsDocRef, { hotspotEnabled: newValue }, { merge: true });
    hotspotEnabled.value = newValue;

    updateHotspotEnabledCache(newValue);

    // Show success toast
    toastMessage.value = '熱點功能設定已成功更新！'; // "Hotspot setting updated successfully!"
    showToast.value = true;

    // Automatically hide the toast after 3 seconds
    setTimeout(() => {
      showToast.value = false;
    }, 3000);
  } catch (error) {
    console.error('Error updating hotspot setting:', error);
    // Handle error message if needed
  }
};

/**
 * Fetches the subscription details of the current user.
 */
const fetchSubscription = async () => {
  if (!currentUser.value) {
    subscription.value = null;
    return;
  }

  const dbInstance = getFirestore();
  const subsRef = collection(dbInstance, 'customers', currentUser.value.uid, 'subscriptions');
  const subsQuery = query(
    subsRef,
    where('status', 'in', ['trialing', 'active', 'past_due', 'unpaid'])
  );

  const subsSnapshot = await getDocs(subsQuery);
  subscription.value = subsSnapshot.docs.length > 0 ? subsSnapshot.docs[0].data() : null;
};

/**
 * Toggles the animation setting and updates Firestore.
 */
const toggleAnimation = () => {
  if (canSetFeature('animation')) {
    updateAnimationEnabled(animationEnabled.value);
  }
};

/**
 * Toggles the blur setting and updates Firestore.
 */
const toggleBlur = () => {
  if (canSetFeature('blur')) {
    updateBlurEnabled(blurEnabled.value);
  }
};

/**
 * Toggles the hotspot setting and updates Firestore.
 */
const toggleHotspot = () => {
  if (canSetFeature('hotspot')) {
    updateHotspotEnabled(hotspotEnabled.value);
  }
};

/**
 * Navigates the user to the Home page.
 */
const goHome = () => {
  router.push({ name: 'Home2' });
};

/**
 * Navigates the user to the Subscription page.
 */
 const goToSubscribe = () => {
  if (subscribedSection.value) {
    subscribedSection.value.$el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

// Watch for route changes to possibly skip authorization
watch(
  () => route.query,
  (newQuery) => {
    if (newQuery.skip == 'true') {
      authorized.value = true;
    }
  },
  { immediate: true }
);
</script>
