<template>
  <div class="quiz-container">
    <svg :id="quizId" xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <!-- Horizontal lines -->
      <line x1="0" y1="50" x2="150" y2="50" stroke="#DDD" />
      <line x1="0" y1="100" x2="150" y2="100" stroke="#DDD" />
      <!-- Vertical lines -->
      <line x1="50" y1="0" x2="50" y2="150" stroke="#DDD" />
      <line x1="100" y1="0" x2="100" y2="150" stroke="#DDD" />
    </svg>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch, nextTick } from 'vue';
import HanziWriter from 'hanzi-writer';

// Props
const props = defineProps({
  word: {
    type: String,
    required: true,
  },
  idSuffix: {
    type: String,
    required: true,
  },
});

// Dynamic ID for the SVG container
const quizId = `quiz-${props.idSuffix}`;
const writer = ref(null);


onMounted(() => {
  writer.value = HanziWriter.create(quizId, props.word, {
    width: 150,
    height: 150,
    padding: 10,
    showCharacter: true,
    showOutline: true,
    highlightOnComplete: true,
  });
});

const setCharacterAndQuiz = () => {
  nextTick(() => {
    writer.value.setCharacter(props.word);
    writer.value.quiz();
  });
};

// Watch for changes in props
watch([() => props.word, () => props.idSuffix], () => {
  setCharacterAndQuiz();
}, { immediate: true });

</script>

<style scoped>
.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
