// router.js
import { createRouter, createWebHistory } from 'vue-router';
import contentData from './contentData';

// Import other components
import Home from './Home.vue';
import Home2 from './Home2.vue';
import WordApp2 from './WordApp2.vue';
import WordApp3 from './WordApp3.vue';
import SignIn from './auth/SignIn.vue';
import SignUp from './auth/SignUp.vue';
import RetrievePassword from './auth/RetrievePassword.vue';
import Account from './auth/Account.vue';
import CustomWords from './components/CustomWords.vue'
import { getCurrentUser, db } from './firebase/firebase';
import { FEATURE_PERMISSIONS, ADVANCED_PLAN } from './constants/plan';
import { doc, getDoc } from 'firebase/firestore';

const contentRoutes = contentData.map(item => ({
  path: item.path,
  component: item.component,
  name: item.title,
  meta: {
    requireAuth: item.loginForAccess || item.subscriptionForAccess,
    subscriptionForAccess: item.subscriptionForAccess,
  },
}));

const routes = [
  // { path: '/', component: Home, name: 'Home' },
  { path: '/', component: Home2, name: 'Home2' },
  { path: '/home', component: Home, name: 'Home' },
  // { path: '/home2', component: Home2, name: 'Home2' },
  { path: '/app2', component: WordApp2, name: 'App2' },
  { path: '/app3', component: WordApp3, name: 'App3', meta: { requireAuth: true } },
  { path: '/login', component: SignIn, name: 'Login', meta: { guestOnly: true } },
  { path: '/signup', component: SignUp, name: 'Create' },
  { path: '/forgot', component: RetrievePassword, name: 'Forgot' },
  { path: '/account', component: Account, name: 'Account', meta: { requireAuth: true } },
  { path: '/custom', component: CustomWords, name: 'CustomWords', meta: {requireAuth: true} },

   // Dynamic Route for Content
   {
    path: '/:category/:title',
    name: 'DynamicContent',
    component: () => import('./DynamicContent.vue'), // Lazy-loaded
  },
  // Spread the content routes
  // ...contentRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.requireAuth);
  const isGuestOnly = to.matched.some(record => record.meta.guestOnly);
  const subscriptionForAccess = to.matched.some(record => record.meta.subscriptionForAccess);

  const currentUser = await getCurrentUser();

  if (requireAuth && !currentUser) {
    return next('/login');
  }

  if (isGuestOnly && currentUser) {
    return next('/account');
  }

  if (subscriptionForAccess && !currentUser) {
    return next('/login');
  }


  // If none of the above conditions are met, proceed as normal
  next();
});

export default router;
