<template>
  <div class="observe-container">
    <div :id="animateTargetId"></div>
    <div class="audio-icon" @click="playSound">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path d="M12 3v18c-4.6-2.4-8-7.1-8-12s3.4-9.6 8-12zm0 0c4.6 2.4 8 7.1 8 12s-3.4 9.6-8 12zm-2 3.8v10.4c-2.5-1.7-4-4.8-4-8s1.5-6.3 4-8zm8 0c2.5 1.7 4 4.8 4 8s-1.5 6.3-4 8z"/>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { onMounted, defineProps, watch, nextTick } from 'vue';
import HanziWriter from 'hanzi-writer';

// Import or set up your audio
const audio = new Audio();

// Props
const props = defineProps({
  word: {
    type: String,
    required: true,
  },
  imagePath: {
    type: String,
    required: false,
  },
  idSuffix: {
    type: String,
    required: true,
  },
  audioPath: {
    type: String,
    required: false,
  },
  option: {
    type: Object,
    required: false
  },
  radicalOption: {
    type: Object,
    required: false
  }
});

// Dynamic ID for the SVG container
const animateTargetId = `animateTarget-${props.idSuffix}`;
// Dynamic ID for the pattern
const patternId = `imgPattern-${props.idSuffix}`;

// Variable for size
const size = 200;  // Change this to control the size
const smallImageSize = 500;

onMounted(() => {
  setCharacter();
  loadAudio();  // Load audio on mount
});

const addZoomAnimation = (path, duration = '5s') => {
  const animateTransform = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform');
  animateTransform.setAttribute('attributeName', 'transform');
  animateTransform.setAttribute('attributeType', 'XML');
  animateTransform.setAttribute('type', 'scale');
  animateTransform.setAttribute('from', '1 1');
  animateTransform.setAttribute('to', '1.1 1.1');
  animateTransform.setAttribute('begin', '0.3s');
  animateTransform.setAttribute('dur', duration);
  animateTransform.setAttribute('repeatCount', 'indefinite');
  animateTransform.setAttribute('keyTimes', '0;0.5;1');
  animateTransform.setAttribute('values', '1 1;1.1 1.1;1 1');
  animateTransform.setAttribute('keySplines', '0.42 0 0.58 1;0.42 0 0.58 1');
  path.appendChild(animateTransform);
}

const addColorAnimation = (path, startColor, endColor, duration = '5s') => {
  const colorChangeAnimation = document.createElementNS('http://www.w3.org/2000/svg', 'animate');
  colorChangeAnimation.setAttribute('attributeName', 'fill');
  colorChangeAnimation.setAttribute('from', startColor);   // Start color
  colorChangeAnimation.setAttribute('to', endColor);  // End color
  colorChangeAnimation.setAttribute('begin', '0s');
  colorChangeAnimation.setAttribute('dur', duration);      // Complete the color change in specified duration
  colorChangeAnimation.setAttribute('repeatCount', 'indefinite');
  colorChangeAnimation.setAttribute('keyTimes', '0;0.5;1');
  colorChangeAnimation.setAttribute('values', `${startColor};${endColor};${startColor}`); // Cycle between the colors
  path.appendChild(colorChangeAnimation);
}

const addLeftRightAnimation = (path, duration = '2s') => {
  const translateAnimation = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform');
  translateAnimation.setAttribute('attributeName', 'transform');
  translateAnimation.setAttribute('attributeType', 'XML');
  translateAnimation.setAttribute('type', 'translate');
  translateAnimation.setAttribute('from', '0 0');
  translateAnimation.setAttribute('to', '100 0');  // Move 100 units to the right
  translateAnimation.setAttribute('begin', '0s');
  translateAnimation.setAttribute('dur', duration);
  translateAnimation.setAttribute('repeatCount', 'indefinite');
  translateAnimation.setAttribute('keyTimes', '0;0.5;1');
  translateAnimation.setAttribute('values', '0 0;50 0;0 0'); // Move to 50% and return
  path.appendChild(translateAnimation);
} 

const addWiggleAnimation = (path) => {
  const wiggleAnimation = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform');
  wiggleAnimation.setAttribute('attributeName', 'transform');
  wiggleAnimation.setAttribute('attributeType', 'XML');
  wiggleAnimation.setAttribute('type', 'translate');
  wiggleAnimation.setAttribute('from', '-10 0');   // Start 5 units to the left
  wiggleAnimation.setAttribute('to', '10 0');      // Move 5 units to the right
  wiggleAnimation.setAttribute('begin', '0s');
  wiggleAnimation.setAttribute('dur', '0.5s');    // Complete the wiggle in 0.1 seconds
  wiggleAnimation.setAttribute('repeatCount', 'indefinite');
  wiggleAnimation.setAttribute('keyTimes', '0;0.5;1');
  wiggleAnimation.setAttribute('values', '-5 0; 5 0; -5 0'); // Wiggle back and forth
  path.appendChild(wiggleAnimation);
}

const addPulseAnimation = (path) => {
  const pulseAnimation = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform');
  pulseAnimation.setAttribute('attributeName', 'transform');
  pulseAnimation.setAttribute('attributeType', 'XML');
  pulseAnimation.setAttribute('type', 'scale');
  pulseAnimation.setAttribute('from', '1 1');   // Start at normal size
  pulseAnimation.setAttribute('to', '1.05 1.05'); // Scale up by 5% for a subtle effect
  pulseAnimation.setAttribute('begin', '0s');
  pulseAnimation.setAttribute('dur', '1.5s');   // Complete the pulse in 1.5 seconds
  pulseAnimation.setAttribute('repeatCount', 'indefinite');
  pulseAnimation.setAttribute('keyTimes', '0;0.5;1');
  pulseAnimation.setAttribute('values', '1 1; 1.05 1.05; 1 1'); // Pulse up and back down
  pulseAnimation.setAttribute('keySplines', '0.42 0 0.58 1; 0.42 0 0.58 1'); // Ease-in-out for smooth motion
  path.appendChild(pulseAnimation);
}


const setCharacter = () => {
  nextTick(() => {
    HanziWriter.loadCharacterData(props.word).then((charData) => {
      console.log(charData)
      const animateTarget = document.getElementById(animateTargetId);
      animateTarget.innerHTML = '';

      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.style.width = `${size}px`;
      svg.style.height = `${size}px`;
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

      const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
      svg.appendChild(defs);

      // Step 4: Create the pattern element with a unique ID
      const pattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
      pattern.setAttribute('id', patternId);
      pattern.setAttribute('patternUnits', 'userSpaceOnUse');
      pattern.setAttribute('width', smallImageSize.toString());
      pattern.setAttribute('height', smallImageSize.toString());
      defs.appendChild(pattern);

      // Step 5: Create the image element inside the pattern
      if (props.imagePath) {
        const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', props.imagePath);
        image.setAttribute('x', '0');
        image.setAttribute('y', '0');
        image.setAttribute('width', smallImageSize.toString());
        image.setAttribute('height', smallImageSize.toString());
        image.setAttribute('transform', `rotate(180, ${smallImageSize / 2}, ${smallImageSize / 2})`); // Rotate to correct orientation
        pattern.appendChild(image);
      }

      animateTarget.appendChild(svg);
      const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');

      // Set the transform property on the g element so the character renders at size x size
      const transformData = HanziWriter.getScalingTransform(size, size);
      group.setAttributeNS(null, 'transform', transformData.transform);
      svg.appendChild(group);

      charData.strokes.forEach((strokePath, index) => {
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttributeNS(null, 'd', strokePath);

        // style the character paths
        path.style.fill = '#444';

        var isRadical = false;
        if(charData.radStrokes) {
          if (charData.radStrokes.includes(index)) {
            isRadical = true;
          }
        } else {
          // all of them are radical
          isRadical = true;
        }

        // Add zoom animation
        console.log(props.option)
        if (props.option && props.option.animationTypeZoom && props.option.animationTypeZoomStrokes.includes(index)) {
          addZoomAnimation(path)
        }

        // Add color animation
        if (props.option && props.option.animationTypeColor && props.option.animationTypeColorStrokes.includes(index)) {
          addColorAnimation(path, props.option.animationTypeColorStartColor, props.option.animationTypeColorEndColor);
        }

        // Left Right animation
        if (props.option && props.option.animationTypeLeftRight && props.option.animationTypeLeftRightStrokes.includes(index)) {
          addLeftRightAnimation(path)
        }

        // Wiggle animation
        if (props.option && props.option.animationTypeWiggle && props.option.animationTypeWiggleStrokes.includes(index)) {
          addWiggleAnimation(path)
        }
        
        // Pulse animation
        if (props.option && props.option.animationTypePulse && props.option.animationTypePulseStrokes.includes(index)) {
          addPulseAnimation(path)
        }

        if (isRadical) {
          path.style.fill = `url(#${patternId})`; // Apply the unique pattern fill

          if (props.radicalOption && props.radicalOption.animationTypeZoom) {
            addZoomAnimation(path);
          }

          if (props.radicalOption && props.radicalOption.animationTypeColor) {
            addColorAnimation(path, props.radicalOption.animationTypeColorStartColor, props.radicalOption.animationTypeColorEndColor);
          }

          if (props.radicalOption && props.radicalOption.animationTypeLeftRight) {
            addLeftRightAnimation(path);
          }

          if (props.radicalOption && props.radicalOption.animationTypeWiggle) {
            addWiggleAnimation(path);
          }

          if (props.radicalOption && props.radicalOption.animationTypePulse) {
            addPulseAnimation(path);
          }
        }

        group.appendChild(path);
      });
    });
  });
};

// Load audio
const loadAudio = () => {
  audio.src = props.audioPath;
};

// Play audio when the icon is clicked
const playSound = () => {
  audio.play();
};

// Watch for changes in props
watch([() => props.word, () => props.idSuffix], () => {
  setCharacter();
  loadAudio();  // Reload audio when props change
}, { immediate: true });
</script>

<style scoped>
.observe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-icon {
  margin-top: 10px;
  cursor: pointer;
}

.audio-icon svg {
  fill: #444;
  width: 24px;
  height: 24px;
  transition: fill 0.3s ease;
}

.audio-icon:hover svg {
  fill: #007BFF; /* Highlight color on hover */
}
</style>
