<template>
  <nav class="bg-gray-800 text-white p-2 flex justify-between items-center h-12">
    <!-- Left: Arrow and Image -->
    <div class="flex items-center space-x-3">
      <!-- Arrow Icon -->
      <ArrowUturnLeftIcon @click="handleClick" class="ml-3 mr-3 h-5 w-5 hover:text-gray-400 cursor-pointer" />

      <!-- Logo Image next to Arrow -->
      <img src="/dolphin.jpg" alt="Logo" class="h-8 w-8 object-cover rounded-full" />
    </div>

    <!-- Center: Title -->
    <div class="absolute left-1/2 transform -translate-x-1/2 text-center">
      {{ title }}
    </div>

    <!-- Right: Logout Button -->
    <!-- <div class="ml-auto mr-3">
      <button @click="handleLogout"
        class="border border-white px-2 py-1 rounded-md text-white hover:bg-gray-700 hover:border-gray-700 text-sm">
        登出
      </button>
    </div> -->
  </nav>
</template>

<script setup>
import { ArrowUturnLeftIcon } from '@heroicons/vue/24/outline';

// Define the emit event
const emit = defineEmits(['back-clicked', 'logout-clicked']);

// Define props to receive the title
const props = defineProps({
  title: {
    type: String,
    default: 'Default Title' // You can set a default title here
  }
});

// Handle the click event and emit 'back-clicked'
const handleClick = () => {
  emit('back-clicked');
};

// Handle the logout button click and emit 'logout-clicked'
const handleLogout = () => {
  emit('logout-clicked');
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
