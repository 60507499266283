<!-- HomeNavBar.vue -->
<template>
  <div>
    <!-- Navigation Bar -->
    <nav class="bg-gray-800 text-white p-2 flex justify-between items-center h-12">
      
      <!-- Menu Icon and Logo -->
      <div class="flex items-center space-x-2 ml-1">
        <!-- Menu Icon -->
        <button @click="toggleSidebar" class="focus:outline-none">
          <!-- Hamburger Icon -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                  d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
        
        <!-- Logo Image -->
        <img src="/dolphin.jpg" alt="Logo" class="h-6 w-6 object-cover rounded-full" />
        <!-- Logo Text -->
        <span class="text-xl font-medium font-xiaolai">
          小海豚中文工作室
        </span>
      </div>

      <!-- User Account Buttons -->
      <div class="flex items-center space-x-4 mr-2">
        <span v-if="currentUser">
          <!-- Display user's name or email if needed -->
          <!-- Example: {{ currentUser.displayName || currentUser.email }} -->
        </span>
        <!-- Sign In Button for Unauthenticated Users -->
        <router-link v-else to="/login"
          class="bg-blue-500 px-3 py-1 rounded-md text-white hover:bg-blue-600 text-sm mr-4">
          登入
        </router-link>
      </div>
    </nav>

    <!-- Sidebar Menu -->
    <transition name="slide">
      <div v-if="isSidebarOpen" class="fixed inset-0 z-40 flex">
        <!-- Sidebar -->
        <div class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-200 z-50">
          <div class="absolute top-0 left-0 ml-6 pt-4">
            <div class="flex items-center space-x-2 ml-1">
              <img src="/dolphin.jpg" alt="Logo" class="h-6 w-6 object-cover rounded-full" />
              <span class="text-xl font-bold">小海豚中文工作室</span>
            </div>
          </div>
          <hr />

          <!-- Close button -->
          <div class="absolute top-0 right-0 -mr-12 pt-2">
            <button @click="toggleSidebar" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span class="sr-only">Close sidebar</span>
              <!-- Close Icon -->
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <!-- Sidebar Content -->
          <div class="mt-16 flex-1 h-0 overflow-y-auto flex flex-col justify-between">
            <nav class="px-4 space-y-2">
              <!-- <router-link to="/custom" @click="onNavClick" class="flex items-center space-x-2 px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-200">
                <AdjustmentsHorizontalIcon class="h-7 w-7 text-gray-600" />
                <span>自訂文字</span>
              </router-link> -->

             
              <!-- Add more navigation links here -->
            </nav>

            <!-- Sidebar Footer -->
            <div class="px-4 pb-4">
              <div v-if="currentUser">
                <!-- 我的帳戶 Button -->
                <router-link to="/account" @click="onNavClick"
                  class="w-full flex items-center justify-center space-x-2 px-3 py-2 mb-4 rounded-md text-base font-medium text-white bg-sky-500 hover:bg-sky-600">
                  <!-- Account Icon -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                          d="M5.121 17.804A13.937 13.937 0 0112 16c2.05 0 4.004.294 5.879.804M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span class="font-bold">我的帳戶</span>
                </router-link>

                <!-- Logout Button -->
                <button @click="handleLogout"
                  class="w-full border border-gray-700 px-2 py-1 rounded-md text-gray-700 bg-white text-sm">
                  登出
                </button>
              </div>
              
              <!-- Social Media Icons -->
              <div class="flex space-x-4 justify-center mt-6">
                <!-- Instagram Icon -->
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" class="text-gray-600 hover:text-gray-800">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                          d="M7.75 2h8.5A5.75 5.75 0 0122 7.75v8.5A5.75 5.75 0 0116.25 22h-8.5A5.75 5.75 0 012 16.25v-8.5A5.75 5.75 0 017.75 2z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                          d="M16.5 7.5v.01M12 7.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                          d="M17.5 6.5L16.5 7.5" />
                  </svg>
                </a>
                
                <!-- Facebook Icon -->
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" class="text-gray-600 hover:text-gray-800">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.403.597 24 1.325 24h11.495v-9.294H9.691v-3.622h3.129V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.464.099 2.795.143v3.24l-1.918.001c-1.504 0-1.796.715-1.796 1.764v2.313h3.587l-.467 3.622h-3.12V24h6.116C23.403 24 24 23.403 24 22.676V1.325C24 .597 23.403 0 22.675 0z"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Backdrop -->
        <div @click="toggleSidebar" class="fixed inset-0 bg-black opacity-50 z-0" aria-hidden="true"></div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, onBeforeUnmount } from 'vue';
import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline'; // Import the desired icon
import IconParkSolidExperiment from '@/assets/icon/IconParkSolidExperiment.vue'

// Accept currentUser as a prop
const props = defineProps({
  currentUser: {
    type: Object,
    default: null,
  },
});

// Define the emit event
const emit = defineEmits(['logout']);

// Handle the logout click event and emit 'logout'
const handleLogout = () => {
  emit('logout');
};

const onNavClick = () => {
  // Close the sidebar after navigation
  isSidebarOpen.value = false;
};

// Sidebar visibility state
const isSidebarOpen = ref(false);

// Toggle sidebar visibility
const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

// Watcher to toggle body scroll based on sidebar state
watch(isSidebarOpen, (newVal) => {
  if (newVal) {
    // Disable body scroll
    document.body.style.overflow = 'hidden';
  } else {
    // Enable body scroll
    document.body.style.overflow = '';
  }
});

// Ensure that body scroll is enabled when component is unmounted
onBeforeUnmount(() => {
  document.body.style.overflow = '';
});
</script>

<style scoped>
/* Slide transition for sidebar */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter-from {
  transform: translateX(-100%);
}
.slide-enter-to {
  transform: translateX(0);
}
.slide-leave-from {
  transform: translateX(0);
}
.slide-leave-to {
  transform: translateX(-100%);
}

/* Optional: Prevent body from being scrollable via touch on mobile devices */
.no-scroll {
  touch-action: none;
}
</style>
