// contentData.js

export default [
  // Words
  {
    category: 'words',
    title: '書包',
    imageSrc: require('@/assets/schoolbag.webp'),
    path: '/words/書包',
    componentPath: 'vocab/書包.vue',
    loginForAccess: false,
    subscriptionForAccess: false,
    editorChoice: true,
  },
  {
    category: 'words',
    title: '小學生',
    imageSrc: require('@/assets/primaryschoolstudent.webp'),
    path: '/words/小學生',
    componentPath: 'vocab/小學生.vue',
    loginForAccess: false,
    subscriptionForAccess: false,
  },
  {
    category: 'words',
    title: '合格',
    imageSrc: require('@/assets/vocab/合格/pass.webp'),
    path: '/words/合格',
    componentPath: 'vocab/合格.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  // Radicals
  {
    category: 'radicals',
    title: '部首「水」(氵)',
    imageSrc: require('@/assets/water-droplet-2.webp'),
    path: '/radicals/部首「水」(氵)',
    componentPath: 'radicals/Water3.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  {
    category: 'radicals',
    title: '部首「木」',
    imageSrc: require('@/assets/wood.webp'),
    path: '/radicals/部首「木」',
    componentPath: 'radicals/Wood.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
    editorChoice: true,
  },
  {
    category: 'radicals',
    title: '部首「口」',
    imageSrc: require('@/assets/mouth.webp'),
    path: '/radicals/部首「口」',
    componentPath: 'radicals/Mouth.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
  },
  // Breakdowns
  {
    category: 'breakdowns',
    title: '字',
    imageSrc: require('@/assets/wood.webp'),
    path: '/breakdowns/字',
    componentPath: 'breakdowns/字.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
    editorChoice: true,
  },
  {
    category: 'breakdowns',
    title: '彩',
    imageSrc: require('@/assets/wood.webp'),
    path: '/breakdowns/彩',
    componentPath: 'breakdowns/彩.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
  },
  {
    category: 'breakdowns',
    title: '課',
    imageSrc: require('@/assets/wood.webp'),
    path: '/breakdowns/課',
    componentPath: 'breakdowns/課.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
    editorChoice: true,
  },
  {
    category: 'breakdowns',
    title: '本',
    imageSrc: require('@/assets/wood.webp'),
    path: '/breakdowns/本',
    componentPath: 'breakdowns/本.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
  },
  {
    category: 'breakdowns',
    title: '你',
    path: '/breakdowns/你',
    componentPath: 'breakdowns/你.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  {
    category: 'breakdowns',
    title: '我',
    path: '/breakdowns/我',
    componentPath: 'breakdowns/我.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  {
    category: 'breakdowns',
    title: '上',
    path: '/breakdowns/上',
    componentPath: 'breakdowns/上.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  {
    category: 'breakdowns',
    title: '下',
    path: '/breakdowns/下',
    componentPath: 'breakdowns/下.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  {
    category: 'breakdowns',
    title: '左',
    path: '/breakdowns/左',
    componentPath: 'breakdowns/左.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  {
    category: 'breakdowns',
    title: '右',
    path: '/breakdowns/右',
    componentPath: 'breakdowns/右.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  {
    category: 'breakdowns',
    title: '雞',
    path: '/breakdowns/雞',
    componentPath: 'breakdowns/雞.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
    editorChoice: true
  },
  {
    category: 'breakdowns',
    title: '豬',
    path: '/breakdowns/豬',
    componentPath: 'breakdowns/豬.vue',
    loginForAccess: true,
    subscriptionForAccess: false,
  },
  {
    category: 'breakdowns',
    title: '牛',
    path: '/breakdowns/牛',
    componentPath: 'breakdowns/牛.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
  },
  {
    category: 'breakdowns',
    title: '蛋',
    path: '/breakdowns/蛋',
    componentPath: 'breakdowns/蛋.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
  },
  {
    category: 'breakdowns',
    title: '飯',
    path: '/breakdowns/飯',
    componentPath: 'breakdowns/飯.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
  },
  {
    category: 'breakdowns',
    title: '菜',
    path: '/breakdowns/菜',
    componentPath: 'breakdowns/菜.vue',
    loginForAccess: true,
    subscriptionForAccess: true,
  },
];
