<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128" >

      <path id="notoV1Dolphin0" fill="#006ca2" stroke="white" stroke-width="5"
        d="M69.6 16.36c-15.87-1.85-32.5 4.54-42.67 16.95c-2.52 3.08-4.66 6.69-6.25 10.34c-2.32 5.38-3.24 11.3-2.21 17.09c.78 4.36 1.92 8.67 2.14 13.11c.22 4.29-.91 8.23-1.64 12.4c-.92 5.3-.99 12.25 6.29 11.57c3.74-.35 6.23-4 8.41-6.65c2.56-3.11 5.28-6.19 8.46-8.69c5.52-4.33 11.63-5.06 18.44-5.41c3.21-.17 6.46-.21 9.65-.77c2.5-.43 6.2-2.29 8.64-1.26c6.07 2.57 2.54 12.87.88 17.06c-2.7 6.84-8.33 13.74-16.8 11.86c-3.11-.69-5.22-.49-8.3-.03c-2.53.38-6.16 1.67-7.24 4.27c-1.79 4.39 7.76 4.05 10.05 4.05c1.97 0 3.89-.81 5.88-.59c3.11.34 1.9 3.02 2.6 5.2c.72 2.22 1.84 4.18 2.88 6.23c1.05 2.08 5.28 6.62 7.42 3.17c2.6-4.21.57-9.86.44-14.39c-.11-3.7 3.29-6.13 6.21-7.5c21.21-9.94 31.98-35.57 24.89-57.78c-.67-2.12-1.52-4.18-2.52-6.17c-6.66-13.1-20.73-22.18-35.16-24.03c-.17 0-.33-.02-.49-.03
        M66.07 16.09C70.3 10.24 76.56 6.07 83.25 3.56c5.68-2.13 14.79-4.51 20.68-2.05c2.93 1.22 3.59 4.82 2.07 7.58c-1.2 2.18-2.35 4.39-3.4 6.65c-1.88 4.09-3.51 8.47-4.1 12.95c-.18 1.45-.11 2.89-.29 4.35c-.73.1-1.7.14-2.41-.01c-3.13-.65-5.35-3.38-7.95-5.12c-2.5-1.67-5.16-3.07-7.94-4.21c-3.44-1.4-7.03-2.38-10.64-3.24c-1.3-.32-5.33-.95-4.11-2.99c.27-.45.58-.91.91-1.38m7.88 55.93c1.75-2.98 5.33-4.83 8.85-3.95c.5.13 1.01.29 1.48.49c.31.14.88.61 1.22.62c.03-.17-.07-.22-.1-.38c-.06-.26-.07-.47-.07-.74c0-.48.08-1-.06-1.47c-.17-.51-.48-.54-.84-.83c-.42-.32-.71-.84-1.1-1.21c-.4-.38-.85-.71-1.34-.96c-1.24-.62-2.51-1.18-3.73-1.85c-.88-.49-1.84-.75-2.73-1.23c-1.18-.65-2.54-.84-3.92-.84c-2.1 0-4.21-.05-6.29.17c-2.92.31-5.98.66-8.65 1.99c-1.11.55-2.12 1.2-2.71 2.3c-.69 1.29-1.08 2.56-1.59 3.92c-.24.62-.19 1.08-.19 1.72c-.01.39-.25 1.09-.25 1.09s3.37-1.27 5.67 4c1.56 3.57 1.68 7.47 3.58 10.98c1.52 2.78 3.67 5.38 6.6 6.73c1.08.5 2.35 1.13 3.46 1.15c2.08.04 2.88-.99 2.95-2.9c.03-.49 0-.98-.03-1.47c-.23-3.33-1.33-6.54-1.48-9.89c-.07-1.58.05-3.18.32-4.74c.14-.97.48-1.88.95-2.7">
      </path>

    <path fill="#1f1f1f"  stroke="white" stroke-width="1" d="M50.76 68.73c-2.6 1.61-6.13.44-7.89-2.64c-1.76-3.07-1.1-6.9 1.49-8.52c2.59-1.63 6.13-.44 7.9 2.62c1.76 3.08 1.09 6.91-1.5 8.54">
    </path>
  </svg>
</template>

<script>
export default {
  name: 'NotoV1Dolphin'
}
</script>