<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100">
    <div class="max-w-md w-full bg-white rounded-lg shadow-md p-8">
      <img src="/dolphin.jpg" alt="Logo" class="mx-auto mb-6 w-12 h-12">
      
      <!-- 提交前的表單 -->
      <div v-if="!emailSent">
        <h2 class="text-2xl font-semibold text-center mb-6">重設您的密碼</h2>
        <p class="text-center mb-4 text-gray-600">
          請輸入您的電子郵件地址，我們將向您發送重設密碼的說明。
        </p>
        <form @submit.prevent="sendResetEmail">
          <input 
            v-model="email" 
            type="email" 
            placeholder="電子郵件地址*" 
            class="w-full p-2 border rounded mb-4" 
            required
          >
          <button type="submit" class="w-full bg-green-500 text-white p-2 rounded">繼續</button>
        </form>
        <p class="text-center mt-4">
          <router-link to="/" class="text-blue-500">返回</router-link>
        </p>
      </div>

      <!-- 提交後的訊息 -->
      <div v-else>
        <h2 class="text-2xl font-semibold text-center mb-6">檢查您的電子郵件</h2>
        <p class="text-center mb-4 text-gray-600">
          我們已向<strong>{{ email }}</strong>發送了一封電子郵件，內附重設密碼的說明。請檢查您的收件箱。
        </p>
        <button @click="resetForm" class="w-full bg-blue-500 text-white p-2 rounded mt-4">返回登入</button>
      </div>
    </div>

    <div class="mt-8">
      <router-link to="/terms" class="text-xs text-gray-500">Terms of Use</router-link>
      <span class="mx-2 text-gray-500">|</span>
      <router-link to="/privacy" class="text-xs text-gray-500">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script>
import { sendPasswordResetEmail } from "firebase/auth";
import { firebaseAuth } from "../firebase/firebase";

export default {
  data() {
    return {
      email: '',
      emailSent: false // 記錄是否已發送電子郵件
    };
  },
  methods: {
    async sendResetEmail() {
      try {
        await sendPasswordResetEmail(firebaseAuth, this.email);
        this.emailSent = true; // 發送郵件後設置標誌為 true
      } catch (error) {
        console.error("發送重設郵件時出錯", error);
      }
    },
    resetForm() {
      this.email = '';
      this.emailSent = false;
      this.$router.push('/login'); // 跳轉到登入頁面
    }
  }
};
</script>

<style scoped>
/* 如果需要可以添加額外的樣式 */
</style>
