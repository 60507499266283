<template>
    <div class="max-w-2xl mx-auto py-8 px-4">
        <!-- 加載中的旋轉圖標（全頁面遮罩）-->
        <div v-if="isLoading" class="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
            <svg class="animate-spin h-16 w-16 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
        </div>

       <!-- 已訂閱的帳戶</h2> -->
        <h2>你已訂閱  <span class="text-xl ml-1 p-1 font-bold mb-6 bg-yellow-100 text-gray-800 border-yellow-500">{{ subscribedPlan }}</span></h2>

        <!-- Subscription cancellation notice -->
        <div v-if="subscription.cancel_at_period_end"
            class="mb-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
            此訂閱將於週期結束時取消。
        </div>

        <!-- Current Period Information -->
        <div class="mb-4 mt-4">
            <p class="text-sm">
                <!-- <span class="">此期訂閱開始日期：</span> -->
                <span class="">開始日期：</span>
                {{ formatDate(subscription.current_period_start.seconds) }}
            </p>
            <!-- <p class="text-lg mt-2">
                <span class="font-semibold">當前週期結束：</span>
                {{ formatDate(subscription.current_period_end.seconds) }}
            </p> -->
        </div>

        <!-- Open Billing Portal Button -->
        <div>
            <button @click="openCustomerPortal"
                class="w-full bg-yellow-500 hover:bg-yellow-600 text-white py-3 px-6 rounded-lg transition duration-300 transform hover:scale-105 hover:shadow-lg max-w-52"
                :disabled="isLoading">
                {{ subscribedPlan != ADVANCED_PLAN ? '升級計劃 ／ 管理賬戶' : '管理賬戶'}}
            </button>
            <div class="text-sm mt-2" v-if="subscribedPlan != ADVANCED_PLAN">
                <div class="animate-bounce text-gray-800">↑升級計劃↑</div>
                <div class="text-gray-600">享用更多功能，全面學習中文</div>
            </div>
        </div>
        
        
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getCurrentUser } from '@/firebase/firebase';
import {BASIC_PLAN, ADVANCED_PLAN, PLAN_DETAILS } from '@/constants/plan'
import { getSubscribedPlan } from '@/utils/CommonUtil'


// Props
const props = defineProps({
    subscription: Object
});
const currentUser = ref(null);

// Reactive data properties
const isLoading = ref(false);

const subscribedPlan = getSubscribedPlan(props.subscription);

// Helper method to format date
const formatDate = (seconds) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(seconds * 1000).toLocaleDateString('en-GB', options);
};

// Method to open the billing portal
const openCustomerPortal = async () => {
    try {
        isLoading.value = true;

        const functions = getFunctions(undefined, 'asia-east2'); // Specify correct Cloud Functions deployment location
        const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
        const { data } = await functionRef({
            returnUrl: `${window.location.origin}/account`,
        });
        window.location.assign(data.url);
    } catch (error) {
        console.error('Failed to open customer portal:', error);
    } finally {
        isLoading.value = false;
    }
};


</script>

<style scoped>
/* Add any custom styles if needed */
</style>