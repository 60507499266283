<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100">
    <div class="max-w-md w-full bg-white rounded-lg shadow-md p-8">
      
      <!-- 帶有返回箭頭和標題的標題欄 -->
      <div class="flex items-center mb-6">
        <router-link to="/login" class="flex items-center text-gray-600">
          <ArrowLeftIcon class="h-6 w-6" />
        </router-link>
        <h2 class="text-2xl font-semibold text-center flex-grow">創建您的帳戶</h2>
      </div>
      
      <img src="/dolphin.jpg" alt="Logo" class="mx-auto mb-6 w-12 h-12">
      
      <!-- 初始電子郵件輸入 -->
      <div v-if="step === 1">
        <form @submit.prevent="enterEmail">
          <input 
            v-model="email" 
            type="email" 
            placeholder="電子郵件地址*" 
            class="w-full p-2 border rounded mb-4" 
            required
            :disabled="loading"
          >
          <button type="submit" class="w-full bg-sky-500 text-white p-2 rounded" :class="{ 'opacity-50': loading }" :disabled="loading">
            繼續
          </button>
        </form>
        <p class="text-center mt-4 text-sm">
          已經有帳戶了嗎？ 
          <router-link to="/login" class="text-blue-500">登入</router-link>
        </p>
      </div>

      <!-- 密碼和顯示名稱輸入 -->
      <div v-else-if="step === 2">
        <h2 class="text-2xl font-semibold text-center mb-6">創建您的帳戶</h2>
        <form @submit.prevent="signup">
          <div class="mb-4">
            <p class="text-center">{{ email }} <button @click="editEmail" class="text-blue-500 text-sm ml-2" :disabled="loading">編輯</button></p>
          </div>
          <!-- 顯示名稱輸入 -->
          <input 
            v-model="displayName" 
            type="text" 
            placeholder="您的名字*" 
            class="w-full p-2 border rounded mb-4" 
            required
            :disabled="loading"
          >
          <!-- 密碼輸入 -->
          <input 
            v-model="password" 
            type="password" 
            placeholder="密碼*" 
            class="w-full p-2 border rounded mb-4" 
            required
            :disabled="loading"
          >

          <!-- 如果註冊失敗，顯示錯誤訊息 -->
          <p v-if="errorMessage" class="text-red-500 text-sm mb-4">{{ errorMessage }}</p>

          <button type="submit" class="w-full bg-sky-500 text-white p-2 rounded" :class="{ 'opacity-50': loading }" :disabled="loading">
            <span v-if="loading" class="flex justify-center items-center">
              <!-- 旋轉圖標 -->
              <svg class="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </span>
            <span v-if="!loading">繼續</span>
          </button>
        </form>
        <p class="text-center mt-4 text-sm">
          已經有帳戶了嗎？ 
          <router-link to="/login" class="text-blue-500">登入</router-link>
        </p>
      </div>
    </div>

    <div class="mt-8">
      <router-link to="/terms" class="text-xs text-gray-500">使用條款</router-link>
      <span class="mx-2 text-gray-500">|</span>
      <router-link to="/privacy" class="text-xs text-gray-500">隱私政策</router-link>
    </div>
  </div>
</template>

<script setup>
// 輸入相關 Vue 依賴
import { ref } from 'vue';
import { useRouter } from 'vue-router'; // 導入 useRouter 以處理路由
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { firebaseAuth } from "../firebase/firebase";
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';

// 定義反應性狀態變量
const email = ref('');
const password = ref('');
const displayName = ref('');
const step = ref(1);
const loading = ref(false); // 控制按鈕和輸入的加載狀態
const errorMessage = ref(''); // 存儲錯誤訊息的反應性變量

// 使用路由實例
const router = useRouter();

// 定義方法
const enterEmail = () => {
  if (email.value) {
    step.value = 2;
  }
};

const editEmail = () => {
  step.value = 1;
  password.value = '';
};

const signup = async () => {
  loading.value = true; // 開始加載
  errorMessage.value = ''; // 清除任何之前的錯誤訊息
  try {
    // 使用電子郵件和密碼創建用戶
    const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email.value, password.value);
    const user = userCredential.user;

    // 使用 updateProfile 設置用戶的顯示名稱
    await updateProfile(user, {
      displayName: displayName.value
    });

    // 註冊成功後處理並導航到主頁面
    router.replace({ name: 'Home' });
  } catch (error) {
    console.error("註冊失敗", error);
    // 將常見的 Firebase 錯誤映射到友好的訊息
    if (error.code === 'auth/email-already-in-use') {
      errorMessage.value = '此電子郵件已被使用。請登入或使用其他電子郵件。';
    } else if (error.code === 'auth/weak-password') {
      errorMessage.value = '密碼太弱。請選擇更強的密碼。';
    } else if (error.code === 'auth/invalid-email') {
      errorMessage.value = '電子郵件地址無效。請輸入有效的電子郵件。';
    } else {
      errorMessage.value = '發生意外錯誤。請稍後再試。';
    }
  } finally {
    loading.value = false; // 停止加載
  }
};
</script>

<style scoped>

</style>
