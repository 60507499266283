<template>
  <div>
    <!-- 加載中的旋轉圖標（全頁面遮罩）-->
    <div v-if="isCreatingSub" class="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
      <svg class="animate-spin h-16 w-16 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
      </svg>
    </div>

    <!-- 加載中的旋轉圖標 -->
    <div v-if="isLoading && !isCreatingSub" class="flex justify-center items-center min-h-[400px]">
      <svg class="animate-spin h-8 w-8 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
      </svg>
    </div>

    <!-- 計劃展示 -->
    <section v-else class="bg-white">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <div class="w-max mx-auto">
            <h2
              class="ml-1 md:ml-4 animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5 text-xl bg-yellow-500 text-white font-bold">
              為您的孩子選擇合適的學習計劃
            </h2>
          </div>

          <!-- Description with Animation Highlights -->
          <p class="mb-5 font-light sm:text-lg mt-2">
            <span class="font-bold text-gray-900">快樂學習中文字，建立</span>
            <span
              class="font-bold bg-gray-100 text-gray-900 px-1 rounded transition duration-500 ease-in-out">語言基礎</span>
          </p>
        </div>

        <!-- 根據產品 ID 確定計劃展示 -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 xl:gap-10">
          <!-- Product 1: prod_QpoufCzzqhAHNH -->
          <div v-if="product1"
            class="relative flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8">
            <!-- Popular Ribbon Tag -->
            <!-- <div class="absolute top-0 right-0 bg-yellow-500 text-white px-3 py-1 text-sm font-bold transform translate-x-2 -translate-y-2 rounded-bl-lg shadow-md">
              熱門
            </div> -->

            <h3 class="mb-4 text-2xl font-semibold">{{ product1.name }}</h3>
            <p class="text-gray-600 sm:text-lg">
              {{ product1.description }}
            </p>
            <!-- 價格部分：置中顯示 -->
            <div class="flex flex-col justify-center items-center my-8">
              <div class="flex justify-center items-center">
                <span class="mr-2 text-xl">HKD$</span>
                <span class="mr-2 text-5xl font-extrabold">
                  {{ formatPrice(product1.prices[0].unit_amount) }}
                </span>
                <span class="text-gray-500">/ {{ product1.prices[0].interval === 'month' ? '月' : 'month' }}</span>
              </div>
            </div>

            <!-- Render List Items -->
            <ul role="list" class="mb-8 space-y-4 text-left">
              <li v-for="(item, index) in product1Features" :key="index" class="flex items-center space-x-3">
                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span>{{ item }}</span>
              </li>
            </ul>
            <button @click="createSub(product1)"
              class="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-3 px-6 rounded-lg transition duration-300 transform hover:scale-105 hover:shadow-lg hover:-translate-y-1">
              參加計劃
            </button>
          </div>

          <!-- Product 2: prod_QzRt0ZZ6zdeXWp -->
          <div v-if="product2"
            class="relative flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8">
            <!-- Popular Ribbon Tag -->
            <div class="absolute top-0 right-0 bg-yellow-500 text-white px-3 py-1 text-sm font-bold transform translate-x-2 -translate-y-2 rounded-bl-lg shadow-md">
              熱門
            </div>

            <h3 class="mb-4 text-2xl font-semibold">{{ product2.name }}</h3>
            <p class="text-gray-600 sm:text-lg">
              {{ product2.description }}
            </p>
            <!-- 價格部分：置中顯示 -->
            <div class="flex flex-col justify-center items-center my-8">
              <div class="flex justify-center items-center">
                <span class="mr-2 text-xl">HKD$</span>
                <span class="mr-2 text-5xl font-extrabold">
                  {{ formatPrice(product2.prices[0].unit_amount) }}
                </span>
                <span class="text-gray-500">/ {{ product2.prices[0].interval === 'month' ? '月' : 'month' }}</span>
              </div>
            </div>

            <!-- Render List Items -->
            <ul role="list" class="mb-8 space-y-4 text-left">
              <li v-for="(item, index) in product2Features" :key="index" class="flex items-center space-x-3">
                <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span>{{ item }}</span>
              </li>
            </ul>
            <button @click="createSub(product2)"
              class="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-3 px-6 rounded-lg transition duration-300 transform hover:scale-105 hover:shadow-lg hover:-translate-y-1">
              參加計劃
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {
  getFirestore,
  getDocs,
  where,
  query,
  collection,
  addDoc,
  onSnapshot,
} from 'firebase/firestore';
import { firebaseAuth } from "../firebase/firebase";

// Reactive data properties
const products = ref([]);
const isLoading = ref(true); // Add loading state
const isCreatingSub = ref(false); // Add loading state for subscription creation

// Product variables
const product1 = ref(null);
const product2 = ref(null);

// List items for product features
const product1Features = [
  '多感官學習體驗，提升學習效率',
  '學習正確的筆順，掌握書寫基礎',
  '親自書寫整個字，加深記憶',
  '掌握漢字的部首，輕鬆認字',
  '按月式付款，無隱藏費用',
  '動畫功能：加強專注，趣味學習',
  '模糊功能：訓練記憶，強化大腦迴路',
];

const product2Features = [
  '多感官學習體驗，提升學習效率',
  '學習正確的筆順，掌握書寫基礎',
  '親自書寫整個字，加深記憶',
  '掌握漢字的部首，輕鬆認字',
  '按月式付款，無隱藏費用',
  '動畫功能：加強專注，趣味學習',
  '模糊功能：訓練記憶，強化大腦迴路',
  '熱點功能：組件字詞，了解中文字模式',
  '個性化學習：自定義詞語輸入，適合學習特定詞語（例如學校作業或面試準備）'
];

// Helper to format the price
const formatPrice = (amount) => {
  return (amount / 100).toFixed(0); // Converts cents to dollars or relevant currency format without decimal points
};

// Fetch products on component mount
const fetchProducts = async () => {
  try {
    const db = getFirestore();
    const productsRef = collection(db, 'products');
    const productsQuery = query(productsRef, where('active', '==', true));

    const productsQuerySnap = await getDocs(productsQuery);

    if (productsQuerySnap.empty) {
      console.error("未找到產品");
    }

    productsQuerySnap.forEach(async (doc) => {
      const pricesRef = collection(db, 'products', doc.id, 'prices');
      const pricesQuerySnap = await getDocs(pricesRef);

      // Filter out archived prices where the 'active' field is false
      const prices = pricesQuerySnap.docs.map((priceDoc) => {
        const priceData = priceDoc.data();
        return {
          id: priceDoc.id,
          ...priceData,
        };
      }).filter(price => price.active); // Only include active prices

      if (prices.length > 0) {
        const product = {
          id: doc.id,
          ...doc.data(),
          prices,
        };

        // Assign product based on product ID
        if (product.id === 'prod_QpoufCzzqhAHNH') {
          product1.value = product;
        } else if (product.id === 'prod_QzRt0ZZ6zdeXWp') {
          product2.value = product;
        }
        products.value.push(product);
      }
    });
  } catch (error) {
    console.error("獲取產品或價格時出錯：", error);
  } finally {
    isLoading.value = false; // Stop loading once products are fetched
  }
};

// Create a subscription (connect to Stripe payment)
const createSub = async (product) => {
  try {
    isCreatingSub.value = true; // Set creating subscription state to true
    console.log(product);
    const db = getFirestore();
    const checkoutSessionRef = collection(
      db,
      'customers',
      firebaseAuth.currentUser.uid,
      'checkout_sessions'
    );

    const docRef = await addDoc(checkoutSessionRef, {
      price: product.prices[0].id, // Using only the first price in the array
      success_url: `${window.location.origin}/account?skip=true`,
      cancel_url: `${window.location.origin}/account`,
    });

    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();

      if (error) {
        console.error(`發生錯誤：${error.message}`);
        isCreatingSub.value = false; // Stop loading in case of error
      } else if (url) {
        window.location.assign(url); // Redirect to Stripe checkout
      }
    });
  } catch (error) {
    console.error('創建訂閱時出錯：', error);
    isCreatingSub.value = false; // Stop loading in case of error
  }
};

// Fetch products when the component is mounted
onMounted(() => {
  fetchProducts();
});
</script>
