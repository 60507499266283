// /utils/CacheUtil.js
import { doc, getDoc, collection,getDocs, query, where } from 'firebase/firestore';
import { db, firebaseAuth } from '@/firebase/firebase';

const SUBSCRIPTION_CACHE_KEY = 'userSubscription';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours


/**
 * Fetches the voice language preference for the current user.
 * Utilizes localStorage to cache the preference and reduce Firestore reads.
 *
 * @returns {Promise<string>} The voice language preference ('cantonese' or 'mandarin').
 */
export const fetchVoiceLanguage = async () => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return 'cantonese'; // Default language for unauthenticated users
  }

  const cacheKey = `voiceLanguage_${currentUser.uid}`;
  const cachedLanguage = localStorage.getItem(cacheKey);

  if (cachedLanguage) {
    console.log('read cachedLanguage success. cachedLanguage is', cachedLanguage);
    return cachedLanguage;
  }

  try {
    // Updated path to 'settings' subcollection
    const settingsDocRef = doc(db, 'customers', currentUser.uid, 'settings', 'preference');
    const settingsDocSnap = await getDoc(settingsDocRef);

    if (settingsDocSnap.exists()) {
      const data = settingsDocSnap.data();
      const voiceLang = data.voiceLanguage || 'cantonese';
      localStorage.setItem(cacheKey, voiceLang);
      return voiceLang;
    } else {
      localStorage.setItem(cacheKey, 'cantonese');
      return 'cantonese';
    }
  } catch (error) {
    console.error('Error fetching voice language:', error);
    return 'cantonese'; // Fallback language in case of error
  }
};


/**
 * Updates the cached voice language preference.
 *
 * @param {string} newLanguage - The new voice language preference.
 */
export const updateVoiceLanguageCache = (newLanguage) => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return;
  }

  const cacheKey = `voiceLanguage_${currentUser.uid}`;
  localStorage.setItem(cacheKey, newLanguage);

  console.log('update cachedLanguage success. newLanguage is', newLanguage)
};


/**
 * Fetches the animation enabled setting for the current user.
 * Utilizes localStorage to cache the setting and reduce Firestore reads.
 *
 * @returns {Promise<boolean>} The animation enabled setting (true or false).
 */
export const fetchAnimationEnabled = async () => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return false; // Default value for unauthenticated users
  }

  const cacheKey = `animationEnabled_${currentUser.uid}`;
  const cachedValue = localStorage.getItem(cacheKey);

  if (cachedValue !== null) {
    console.log('read cachedAnimationEnabled success. cachedAnimationEnabled is', cachedValue);
    return cachedValue === 'true';
  }

  try {
    const settingsDocRef = doc(db, 'customers', currentUser.uid, 'settings', 'preference');
    const settingsDocSnap = await getDoc(settingsDocRef);

    if (settingsDocSnap.exists()) {
      const data = settingsDocSnap.data();
      const animationEnabled = data.animationEnabled !== undefined ? data.animationEnabled : false;
      localStorage.setItem(cacheKey, animationEnabled.toString());
      return animationEnabled;
    } else {
      localStorage.setItem(cacheKey, 'false');
      return false;
    }
  } catch (error) {
    console.error('Error fetching animation enabled setting:', error);
    return false; // Fallback value in case of error
  }
};

/**
 * Updates the cached animation enabled setting.
 *
 * @param {boolean} newValue - The new animation enabled setting.
 */
export const updateAnimationEnabledCache = (newValue) => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return;
  }

  const cacheKey = `animationEnabled_${currentUser.uid}`;
  localStorage.setItem(cacheKey, newValue.toString());

  console.log('update cachedAnimationEnabled success. newValue is', newValue);
};

/**
 * Fetches the blur enabled setting for the current user.
 * Utilizes localStorage to cache the setting and reduce Firestore reads.
 *
 * @returns {Promise<boolean>} The blur enabled setting (true or false).
 */
export const fetchBlurEnabled = async () => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return false; // Default value for unauthenticated users
  }

  const cacheKey = `blurEnabled_${currentUser.uid}`;
  const cachedValue = localStorage.getItem(cacheKey);

  if (cachedValue !== null) {
    console.log('read cachedBlurEnabled success. cachedBlurEnabled is', cachedValue);
    return cachedValue === 'true';
  }

  try {
    const settingsDocRef = doc(db, 'customers', currentUser.uid, 'settings', 'preference');
    const settingsDocSnap = await getDoc(settingsDocRef);

    if (settingsDocSnap.exists()) {
      const data = settingsDocSnap.data();
      const blurEnabled = data.blurEnabled !== undefined ? data.blurEnabled : false;
      localStorage.setItem(cacheKey, blurEnabled.toString());
      return blurEnabled;
    } else {
      localStorage.setItem(cacheKey, 'false');
      return false;
    }
  } catch (error) {
    console.error('Error fetching blur enabled setting:', error);
    return false; // Fallback value in case of error
  }
};

/**
 * Updates the cached blur enabled setting.
 *
 * @param {boolean} newValue - The new blur enabled setting.
 */
export const updateBlurEnabledCache = (newValue) => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return;
  }

  const cacheKey = `blurEnabled_${currentUser.uid}`;
  localStorage.setItem(cacheKey, newValue.toString());

  console.log('update cachedBlurEnabled success. newValue is', newValue);
};

/**
 * Fetches the hotspot enabled setting for the current user.
 * Utilizes localStorage to cache the setting and reduce Firestore reads.
 *
 * @returns {Promise<boolean>} The hotspot enabled setting (true or false).
 */
export const fetchHotspotEnabled = async () => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return false; // Default value for unauthenticated users
  }

  const cacheKey = `hotspotEnabled_${currentUser.uid}`;
  const cachedValue = localStorage.getItem(cacheKey);

  if (cachedValue !== null) {
    console.log('read cachedHotspotEnabled success. cachedHotspotEnabled is', cachedValue);
    return cachedValue === 'true';
  }

  try {
    const settingsDocRef = doc(db, 'customers', currentUser.uid, 'settings', 'preference');
    const settingsDocSnap = await getDoc(settingsDocRef);

    if (settingsDocSnap.exists()) {
      const data = settingsDocSnap.data();
      const hotspotEnabled = data.hotspotEnabled !== undefined ? data.hotspotEnabled : false;
      localStorage.setItem(cacheKey, hotspotEnabled.toString());
      return hotspotEnabled;
    } else {
      localStorage.setItem(cacheKey, 'false');
      return false;
    }
  } catch (error) {
    console.error('Error fetching hotspot enabled setting:', error);
    return false; // Fallback value in case of error
  }
};

/**
 * Updates the cached hotspot enabled setting.
 *
 * @param {boolean} newValue - The new hotspot enabled setting.
 */
export const updateHotspotEnabledCache = (newValue) => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return;
  }

  const cacheKey = `hotspotEnabled_${currentUser.uid}`;
  localStorage.setItem(cacheKey, newValue.toString());

  console.log('update cachedHotspotEnabled success. newValue is', newValue);
};


export const fetchSubscription = async () => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    localStorage.removeItem(SUBSCRIPTION_CACHE_KEY);
    return null;
  }

  const cachedData = localStorage.getItem(SUBSCRIPTION_CACHE_KEY);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    const now = Date.now();
    if (now - timestamp < CACHE_DURATION) {
      console.log('Loaded subscription from cache');
      return data;
    }
  }

  // Fetch from Firestore
  const subsRef = collection(db, 'customers', currentUser.uid, 'subscriptions');
  const subsQuery = query(
    subsRef,
    where('status', 'in', ['trialing', 'active', 'past_due', 'unpaid'])
  );

  try {
    const subsSnapshot = await getDocs(subsQuery);
    const subData = subsSnapshot.docs.length > 0 ? subsSnapshot.docs[0].data() : null;

    // Cache the data
    localStorage.setItem(SUBSCRIPTION_CACHE_KEY, JSON.stringify({
      data: subData,
      timestamp: Date.now(),
    }));

    console.log('Fetched subscription from Firestore and updated cache');

    return subData;
  } catch (error) {
    console.error('Error fetching subscription:', error);
    return null;
  }
};

export const clearSubscriptionCache = () => {
  localStorage.removeItem(SUBSCRIPTION_CACHE_KEY);
};

/**
 * Fetches the completed words for the current user.
 * Utilizes localStorage to cache the data and reduce Firestore reads.
 *
 * @returns {Promise<Object>} An object containing completed words and their counts.
 */
export const fetchCompletedWords = async () => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return {}; // Return an empty object for unauthenticated users
  }

  const userId = currentUser.uid;
  const cacheKey = `completedWords_${userId}`;
  const cacheTimestampKey = `${cacheKey}_timestamp`;

  const cachedData = localStorage.getItem(cacheKey);
  const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

  if (cachedData && cachedTimestamp) {
    const now = Date.now();
    if (now - parseInt(cachedTimestamp) < CACHE_DURATION) {
      console.log('Loaded completedWords from cache');
      return JSON.parse(cachedData);
    } else {
      // Cache expired
      localStorage.removeItem(cacheKey);
      localStorage.removeItem(cacheTimestampKey);
    }
  }

  try {
    // Fetch from Firestore
    const completedWordsRef = collection(db, 'customers', userId, 'completedWords');
    const querySnapshot = await getDocs(completedWordsRef);

    const fetchedWords = {};
    querySnapshot.docs.forEach((doc) => {
      const wordId = doc.id.replace('bd-', '');
      const count = doc.data().count || 0;
      fetchedWords[wordId] = count;
    });

    // Cache the data with timestamp
    localStorage.setItem(cacheKey, JSON.stringify(fetchedWords));
    localStorage.setItem(cacheTimestampKey, Date.now().toString());

    console.log('Fetched completedWords from Firestore and updated cache');

    return fetchedWords;
  } catch (error) {
    console.error('Error fetching completed words:', error);
    return {}; // Return an empty object in case of error
  }
};

export const getCompletedWordsCache = () => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return {};
  }

  const userId = currentUser.uid;
  const cacheKey = `completedWords_${userId}`;
  const cachedData = localStorage.getItem(cacheKey);

  if (cachedData) {
    return JSON.parse(cachedData);
  } else {
    return {};
  }
};

/**
 * Updates the cached completed words data.
 *
 * @param {Object} updatedWords - The updated completed words object.
 */
export const updateCompletedWordsCache = (updatedWords) => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return;
  }

  const userId = currentUser.uid;
  const cacheKey = `completedWords_${userId}`;
  const cacheTimestampKey = `${cacheKey}_timestamp`;

  localStorage.setItem(cacheKey, JSON.stringify(updatedWords));
  localStorage.setItem(cacheTimestampKey, Date.now().toString());

  console.log('Updated completedWords cache');
};