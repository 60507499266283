<template>
  <div class="bg-color-blur bg-left-bottom">
  
    <!-- HomeNavBar Component -->
    <HomeNavBar :currentUser="currentUser" @logout="handleLogout" />
  
    <!-- Colorful Grid -->
    <div class="bg-white min-h-screen flex flex-col items-center pt-10">
      <!-- Header Section -->
      <div class="text-center mb-6 font-xiaolai">
        <h1 class="text-3xl text-gray-700 font-medium mb-2">學習中文，每天進步一些</h1>
        <span class="text-lg font-light bg-zinc-400 text-white pt-1 pb-1 pl-3 pr-3 rounded-md">你會感謝今天自己的努力</span>
      </div>
      <div class="grid grid-cols-12 gap-4 w-4/5 max-w-screen-lg">


        <div 
          class="col-span-12 md:col-span-12 relative bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-zinc-400 to-teal-600 h-40 md:h-72 rounded-xl cursor-pointer transition-transform transform hover:scale-105 shadow-right-bottom-light border-2 border-white flex overflow-hidden"
          @click="navigateTo('breakdowns')"
        >
          <!-- Left side: 30% width -->
          <div 
            class="relative flex items-center justify-normal pl-10 bg-gradient-to-b from-zinc-700 to-zinc-500 w-[50%]"
            style="clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);"
          >
            <div class="flex flex-col items-start justify-center text-white space-y-4">
              <RiDashboardHorizontalFill class="h-12 w-12 md:h-20 md:w-20" />
              <div class="flex flex-col items-start space-y-2">
                <span class="text-3xl font-normal">結構</span>
                <span class="text-xs">拆解文字，輕鬆學習中文單字</span>
              </div>
            </div>
          </div>

          <!-- Right side: 70% width -->
          <div class="w-[50%] flex items-center justify-center">
            <NotoDolphin class="h-10 w-10 md:h-20 md:w-20 text-white" />
          </div>
        </div>



        <!-- 部首 -->
        <div
          class="col-span-12 md:col-span-12 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-zinc-400 to-blue-600
                h-40 md:h-72 flex items-center justify-center rounded-xl cursor-pointer transition-transform transform hover:scale-105 shadow-right-bottom-light
                border-2 border-white" @click="navigateTo('radicals')">
          <div class="flex flex-col items-center text-white space-y-4">
            <FluentStarLineHorizontal320Filled class="h-12 w-12 md:h-20 md:w-20" />
            <div class="flex flex-col items-center space-y-2">
              <span class="text-3xl font-normal">部首</span>
              <span class="text-xs">部首形象化，快速看懂文字的要訣</span>
            </div>
          </div>

          <NotoV1Dolphin class="absolute bottom-1 right-4 h-10 w-10 md:h-20 md:w-20" />
        </div>


        <!-- 字詞 -->
        <!-- <div
          class="col-span-12 md:col-span-10 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-red-400 to-red-600 h-32 flex items-center justify-center rounded-xl cursor-pointer transition-transform transform hover:scale-105 shadow-right-bottom-light relative"
          @click="navigateTo('words')"
        >
          <div class="flex flex-col items-center text-white space-y-2">
            <FluentShapeUnion24Regular class="h-12 w-12" />
            <span class="text-3xl font-normal">字詞</span>
            <img
            :src="gatorImage3"
            alt="Gator Icon"
            class="absolute bottom-2 right-2 h-14 w-14 md:h-24 md:w-24"
          />
          </div>

        </div> -->

        <!-- 自訂文字 -->

        <div
          class="col-span-12 md:col-span-12 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-zinc-400 to-indigo-600
                 h-40 md:h-72 flex items-center justify-center rounded-xl cursor-pointer transition-transform transform hover:scale-105 shadow-right-bottom-light
                border-2 border-white" @click="navigateTo('custom')">
          <div class="flex flex-col items-center text-white space-y-4">
            <IconParkSolidExperiment class="h-12 w-12 md:h-20 md:w-20" />
            <div class="flex flex-col items-center space-y-2">
              <span class="text-2xl font-normal">自訂文字</span>
              <span class="text-xs">做功課的最佳拍檔</span>
            </div>

            <EmojioneV1Dolphin class="absolute bottom-1 right-4 h-10 w-10 md:h-20 md:w-20" />
          </div>
        </div>

        <!-- Sample 1 -->
        <!-- <div
          class="col-span-4 md:col-span-4 bg-stone-500 h-32 flex items-center justify-center rounded-xl cursor-pointer transition-transform transform hover:scale-105 shadow-right-bottom-light"
          @click="navigateTo('sample')">
          <div class="flex flex-col items-center text-white space-y-2">
            <IconParkSolidExperiment class="h-8 w-8" />
            <span class="text-lg font-semibold">Sample 1</span>
          </div>
        </div> -->

        <!-- Sample 2 -->
        <!-- <div
          class="col-span-6 md:col-span-4 bg-stone-500 h-32 flex items-center justify-center rounded-xl cursor-pointer transition-transform transform hover:scale-105 shadow-right-bottom-light"
          @click="navigateTo('sample2')">
          <div class="flex flex-col items-center text-white space-y-2">
            <IconParkSolidExperiment class="h-8 w-8" />
            <span class="text-lg font-semibold">Sample 2</span>
          </div>
        </div> -->

        <!-- Sample 3 -->
        <!-- <div
          class="col-span-6 md:col-span-4 bg-stone-500 h-32 flex items-center justify-center rounded-xl cursor-pointer transition-transform transform hover:scale-105 shadow-right-bottom-light"
          @click="navigateTo('sample3')">
          <div class="flex flex-col items-center text-white space-y-2">
            <IconParkSolidExperiment class="h-8 w-8" />
            <span class="text-lg font-semibold">Sample 3</span>
          </div>
        </div> -->

      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onActivated, nextTick, reactive } from 'vue';

import { useRoute, useRouter } from 'vue-router';
import { getCurrentUser, logout, db } from '@/firebase/firebase';
import HomeNavBar from '@/components/HomeNavBar.vue';
import RiDashboardHorizontalFill from './assets/icon/RiDashboardHorizontalFill.vue';
import FluentStarLineHorizontal320Filled from './assets/icon/FluentStarLineHorizontal320Filled.vue';
import IconParkSolidExperiment from './assets/icon/IconParkSolidExperiment.vue';
import NotoDolphin from './assets/icon/NotoDolphin.vue';
import NotoV1Dolphin from './assets/icon/NotoV1Dolphin.vue';
import EmojioneV1Dolphin from './assets/icon/EmojioneV1Dolphin.vue';


// Route and router
const currentUser = ref(null);
const route = useRoute();
const router = useRouter();


// Caching constants
const CACHE_KEY = 'userSubscription';


const navigateTo = (destin) => {
  switch (destin) {
    case 'breakdowns':
      router.push({ 
          path: '/home', 
          query: { tab: 'breakdowns' } 
        });
      break;
    case 'radicals': 
      router.push({ 
          path: '/home', 
          query: { tab: 'radicals' } 
        });
      break;
    case 'words': 
      router.push({ 
          path: '/home', 
          query: { tab: 'words' } 
        });
      break;
    case 'custom': 
      router.push({path: '/custom'});
      break;
    case 'sample': 
      router.push({path: '/sample'});
      break;
    case 'sample2': 
      router.push({path: '/sample2'});
      break;
    case 'sample3': 
      router.push({path: '/sample3'});
      break;
  }
}

// Load user and data on mount
onMounted(async () => {
  currentUser.value = await getCurrentUser();
});

// Logout function
const handleLogout = async () => {
  try {
    await logout();
    localStorage.removeItem(CACHE_KEY); // Clear cache
    window.location.reload(); // Refresh page
  } catch (error) {
    console.error('Error logging out:', error);
  }
};

</script>

<style lang="css">
/* .font-cartoon {
  font-family: 'Noto Sans TC', sans-serif;
} */
</style>