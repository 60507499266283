<template>
  <div id="app" class="bg-ocean-theme min-h-screen flex items-center justify-center">
    <div class="main-box border-4 border-gray-300 bg-white relative">
      <!-- Words Display -->
      <div class="words-container flex justify-center mb-6">
        <div
          v-for="(item, index) in state.wordData"
          :key="index"
          class="word-circle flex items-center justify-center mx-2 border-2 border-blue-400 rounded-full"
          :class="{
            'text-yellow-500': index === state.currentStep, // Active word
            'text-blue-500': index < state.currentStep,     // Completed words
            'text-gray-500': index > state.currentStep      // Upcoming words
          }"
           :style="{
            'fontFamily': 'ArphicKai',
            'font-weight': 'bold',
            'font-size': '32px'
          }"
        >
          {{ item.word }}
        </div>
      </div>

      <!-- Display the current SetWord based on currentStep -->
      <div class="flex-grow flex justify-center items-center mt-4">
        <SetWord 
          :word="state.wordData[state.currentStep].word" 
          :imagePath="state.wordData[state.currentStep].imagePath" 
          :audioPath="state.wordData[state.currentStep].audioPath" 
          :idSuffix="state.currentStep.toString()" 
        />
      </div>


      <!-- Next Button -->
      <div class="flex justify-center mt-6">
        <button 
          @click="nextWord" 
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md">
          Next Word
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue';
import SetWord from './components/SetWord.vue';
import './index.css'; // Import the Tailwind CSS file

// Reactive state to manage current step and word data
const state = reactive({
  wordData: [
    { word: '氵', imagePath: 'fff.jpg', audioPath: '明.mp3' },
    { word: '艹', imagePath: 'fff.jpg', audioPath: '明.mp3' },
    { word: '亻', imagePath: 'fff.jpg', audioPath: '明.mp3' },
    { word: '眼', imagePath: 'Home-page.jpg', audioPath: '明.mp3' },
    { word: '雲', imagePath: 'rain.jpg', audioPath: '明.mp3' },
    { word: '草', imagePath: 'leaves.jpg', audioPath: '明.mp3' },
    { word: '蟲', imagePath: 'caterpillar.jpg', audioPath: '明.mp3' },
    { word: '海', imagePath: 'fff.jpg', audioPath: '明.mp3' },
    { word: '池', imagePath: 'wood.jpg', audioPath: '明.mp3' },
    { word: '浪', imagePath: 'www.jpg', audioPath: '明.mp3' },
    { word: '沖', imagePath: 'water.jpg', audioPath: '明.mp3' },
    { word: '江', imagePath: 'water.jpg', audioPath: '明.mp3' },
  ],
  currentStep: 0, // Start with the first word
});

// Function to show the next word
function nextWord() {
  if (state.currentStep < state.wordData.length - 1) {
    state.currentStep++; // Move to the next word
  }
}
</script>

<style scoped>
/* Background outside the main box */
.bg-ocean-theme {
  background-image: url('@/assets/c-o3.webp');
  background-repeat: repeat;
  background-size: cover;
}

/* Main box styles */
.main-box {
  width: 80%;
  max-width: 600px; /* Max width to maintain tablet size even on desktop */
  max-height: 600px; /* Max width to maintain tablet size even on desktop */
  aspect-ratio: 1 / 1 !important; /* Ensures the box remains a square */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
}

.word-circle {
  width: 4rem;
  height: 4rem;
  font-size: 1.5rem;
}

/* Responsive styles */
@media (min-width: 1024px) {
  .main-box {
    max-width: 600px; /* Keep tablet size on larger screens */
  }
}

@media (max-width: 1024px) {
  .main-box {
    max-width: 80%; /* Ensure 10% margin on either side */
  }

  .word-circle {
    width: 3rem;
    height: 3rem;
    font-size: 1.25rem;
  }

  button {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .main-box {
    max-width: 80%; /* Maintain 10% margin on either side */
  }

  .word-circle {
    width: 3rem;
    height: 3rem;
    font-size: 1.25rem;
  }

  button {
    width: 80%;
  }
}

</style>
