<template>
    <div class="bg-white rounded-lg shadow-md p-4 animate-pulse">
      <div class="w-full h-48 bg-slate-200 rounded-md mb-4"></div>
      <div class="h-6 bg-slate-200 rounded w-3/4 mb-2"></div>
      <div class="h-4 bg-slate-200 rounded w-1/2"></div>
    </div>
  </template>
  
<script setup>
</script>
