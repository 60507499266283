<template>
  <div id="app" class="p-4 md:p-8 bg-pattern">

    <!-- Progress Bar -->
    <div class="relative w-full flex flex-col">
      <div class="flex w-full">
        <div 
          v-for="(item, index) in state.wordData" 
          :key="index" 
          class="flex-1 h-2 rounded-t-md mx-1"
          :class="{
            'bg-yellow-500': index === state.currentStep, // Active word
            'bg-blue-500': index < state.currentStep,     // Completed words
            'bg-gray-300': index > state.currentStep      // Upcoming words
          }">
        </div>
      </div>
      
      <!-- Words under the progress bar -->
      <div class="flex w-full mt-2">
        <div 
          v-for="(item, index) in state.wordData" 
          :key="index" 
          class="flex-1 text-center mx-1"
          :class="{
            'text-yellow-500': index === state.currentStep, // Active word
            'text-blue-500': index < state.currentStep,     // Completed words
            'text-gray-500': index > state.currentStep      // Upcoming words
          }">
          {{ item.word }}
        </div>
      </div>
    </div>

    <!-- Display the current SetWord based on currentStep -->
    <div class="mt-8">
      <SetWord 
        :word="state.wordData[state.currentStep].word" 
        :imagePath="state.wordData[state.currentStep].imagePath" 
        :idSuffix="state.currentStep.toString()" 
      />
    </div>

    <!-- Next Button -->
    <div class="flex justify-center">
      <button 
        @click="nextWord" 
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md">
        Next Word
      </button>
    </div>
  </div>
</template>

<script setup>


import { reactive } from 'vue';
import SetWord from './components/SetWord.vue';
import './index.css'; // Import the Tailwind CSS file

// Reactive state to manage current step and word data
const state = reactive({
  wordData: [
    { word: '人', imagePath: 'fire.jpg' },
    { word: '水', imagePath: 'wood.jpg' },
    { word: '金', imagePath: 'water.jpg' },
    { word: '針', imagePath: 'fire.jpg' },
    { word: '炒', imagePath: 'fire.jpg' },
    { word: '十', imagePath: 'wood.jpg' },
    { word: '汁', imagePath: 'water.jpg' },
  ],
  currentStep: 0, // Start with the first word
});

// Function to show the next word
function nextWord() {
  if (state.currentStep < state.wordData.length - 1) {
    state.currentStep++; // Move to the next word
  }
}
</script>

<style scoped>
/* Custom background for the app */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;

  min-height: 100vh; /* Full height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #ffffff;
  opacity: 1;
  background-image:  linear-gradient(30deg, #f5f5f5 12%, transparent 12.5%, transparent 87%, #f5f5f5 87.5%, #f5f5f5), linear-gradient(150deg, #f5f5f5 12%, transparent 12.5%, transparent 87%, #f5f5f5 87.5%, #f5f5f5), linear-gradient(30deg, #f5f5f5 12%, transparent 12.5%, transparent 87%, #f5f5f5 87.5%, #f5f5f5), linear-gradient(150deg, #f5f5f5 12%, transparent 12.5%, transparent 87%, #f5f5f5 87.5%, #f5f5f5), linear-gradient(60deg, #f5f5f577 25%, transparent 25.5%, transparent 75%, #f5f5f577 75%, #f5f5f577), linear-gradient(60deg, #f5f5f577 25%, transparent 25.5%, transparent 75%, #f5f5f577 75%, #f5f5f577);
  background-size: 22px 39px;
  background-position: 0 0, 0 0, 11px 19px, 11px 19px, 0 0, 11px 19px;
}

/* Adjust the button size and padding for tablets */
button {
  width: 100%;
  max-width: 300px;
}

@media (min-width: 768px) {
  #app {
    margin-top: 40px;
    padding: 16px 24px;
  }
}

@media (max-width: 768px) {
  /* Mobile specific adjustments */
  #app {
    padding: 16px;
  }
  
  button {
    width: 80%;
  }
}
</style>