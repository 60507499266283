<!-- /src/components/AudioButtonsHorizontal.vue -->
<template>
  <div class="flex space-x-4 absolute top-2 left-2 right-2 z-30 text-sm">
    <button
      @click="playCantoneseAudioFull"
      class="play-audio-button bg-zinc-100 bg-opacity-80 rounded-full p-2 w-full hover:bg-opacity-100 transition flex items-center justify-center space-x-2"
    >
      <span class="icon-wrapper">
        <SpeakerWaveIcon ref="playCantoneseAudioIcon" class="w-5 h-5 text-gray-800" />
      </span>
      <span class="text-gray-800 text-lg">粵語</span>
    </button>

    <button
      @click="playMandarinAudioFull"
      class="play-audio-button-mandarin bg-zinc-100 bg-opacity-80 rounded-full p-4 w-full hover:bg-opacity-100 transition flex items-center justify-center space-x-2"
    >
      <span class="icon-wrapper">
        <SpeakerWaveIcon ref="playMandarinAudioIcon" class="w-5 h-5 text-gray-800" />
      </span>
      <span class="text-gray-800 text-lg">國語</span>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted  } from 'vue';
import { SpeakerWaveIcon } from '@heroicons/vue/24/solid';
import anime from 'animejs/lib/anime.es.js';
import { playCantonese, playMandarin } from '@/utils/CommonUtil.js'

const emit = defineEmits(['play-audio', 'play-mandarin-audio']);

const playCantoneseAudioIcon = ref(null);
const playMandarinAudioIcon = ref(null);


// Define props: either a single word or multiple words/characters
const props = defineProps({
  textToRead: {
    type: String,
    required: true
  },
});

// State to store voices
const voices = ref([]);
const voicesLoaded = ref(false);

// Load voices on mounted
onMounted(() => {
  loadVoices();
  speechSynthesis.addEventListener('voiceschanged', loadVoices, { once: true });
});

const loadVoices = () => {
  voices.value = speechSynthesis.getVoices();
  if (voices.value.length > 0) {
    voicesLoaded.value = true;
  }
};

const playCantoneseAudioFull = () => {
  animateIcon(playCantoneseAudioIcon);
  playCantonese(props.textToRead);
};

const playMandarinAudioFull = () => {
  animateIcon(playMandarinAudioIcon);
  playMandarin(props.textToRead);
};

const animateIcon = (icon) => {
  if (!icon.value) return;

  anime({
    targets: icon.value,
    scale: [
      { value: 1.4, duration: 350, easing: 'easeOutBounce' },
      { value: 1, duration: 350, easing: 'easeInOutBounce' },
    ],
    // Optional: You can add more properties or callbacks if needed
  });
};


</script>

<style scoped>
.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
