<template>
    <div>
        <!-- NavBar Component -->
        <NavBar @back-clicked="goHome" :title="'自訂文字'" />

        <div class="relative max-h-[calc(100vh-3rem)] max-w-full bg-noise-pattern">
            <div class="relative max-w-screen-xl mx-auto flex flex-col h-[calc(100vh-3rem)]">
                <!-- Prompt Text -->
                <div  class="flex flex-col justify-center mt-4 ml-6 transition-opacity duration-300"
                    :class="{ 'opacity-50': characters.length && !isEditing }">
                    <div class="mb-2 text-xl font-semibold">
                        請輸入中文字
                    </div>
                    <div class="mb-4 text-sm text-gray-700">
                        最多3組, 以「,」或「，」去分格，每組最多4個字
                    </div>

                    <div class="flex space-x-2">
                        <input v-model="inputText" type="text" placeholder="請輸入中文" maxlength="18"
                            class="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            @focus="isEditing = true"
                            @blur="isEditing = false" />
                        <button @click="splitCharacters"
                            class="px-4 py-2 bg-sky-500 text-white rounded-md hover:bg-sky-600 focus:outline-none">
                            提交
                        </button>
                    </div>

                    <!-- Error Message -->
                    <div v-if="errorMessage" class="mt-2 text-red-500">
                        {{ errorMessage }}
                    </div>
                </div>

                <!-- Groups Navigation Bar -->
                <nav v-if="groups.length" class="mt-4 flex flex-col items-center justify-center">
                    <ul class="flex space-x-4">
                        <li
                            v-for="(group, groupIndex) in groups"
                            :key="groupIndex"
                            class="px-4 py-2 rounded-md border-2 cursor-pointer"
                            :class="[
                                groupIndex === currentGroupIndex ? 'text-white bg-yellow-500' : 'bg-white text-yellow-500',
                            ]"
                            @click="selectGroup(groupIndex)"
                        >
                            {{ group.join('') }}
                        </li>
                    </ul>
                </nav>

                <!-- Navigation Bar -->
                <nav v-if="characters.length" class="mt-2 flex flex-col items-center justify-center">
                    <ul class="flex space-x-4">
                        <li v-for="(char, index) in characters" :key="index" class="px-4 py-2 rounded-md border-2 cursor-pointer" :class="[
                            index === charIndex ? 'text-white bg-sky-500' : 'bg-white text-sky-500',
                        ]"  @click="selectCharacter(index)">
                            {{ char }}
                        </li>
                    </ul>
                </nav>

                <!-- Main Content Wrapper -->
                <div class="z-10 flex flex-col justify-center">
                    <div id="writeTheWord" class="relative flex flex-col top-4 p-4 justify-center items-center">

                        <div class="relative bg-white"
                            :class="{'opacity-50': congratsIconsActive}"
                            :style="{ width: WRITER_SIZE_IN_PX + 'px', height: WRITER_SIZE_IN_PX + 'px' }">
                            <!-- SVG for HanziWriter -->
                            <svg xmlns="http://www.w3.org/2000/svg" id="character-target-div"
                                class="absolute top-0 left-0 w-full h-full border-4 border-gray-700 rounded-2xl"
                                :class="{ 'z-20': !whiteBoardMode, 'z-10': whiteBoardMode }">
                                <line :x1="WRITER_SIZE_IN_PX / 2" y1="0" :x2="WRITER_SIZE_IN_PX / 2"
                                    :y2="WRITER_SIZE_IN_PX" stroke="#bbbbbb" />
                                <line x1="0" :y1="WRITER_SIZE_IN_PX / 2" :x2="WRITER_SIZE_IN_PX"
                                    :y2="WRITER_SIZE_IN_PX / 2" stroke="#bbbbbb" />
                            </svg>

                            <!-- Canvas Element -->
                            <canvas ref="canvasRef" :width="WRITER_SIZE_IN_PX" :height="WRITER_SIZE_IN_PX"
                                class="absolute top-0 left-0 w-full h-full border-4 border-gray-700 rounded-2xl p-4"
                                :class="{ 'z-20': whiteBoardMode, 'z-10': !whiteBoardMode }">
                            </canvas>
                        </div>
                        
                        <div class="relative flex flex-col w-3/4" :class="{'opacity-50': congratsIconsActive}">

                            <!-- <AudioButtonsHorizontal @play-audio="playCantoneseAudioFull"
                                @play-mandarin-audio="playMandarinAudioFull" /> -->
                                <AudioButtonsHorizontal :textToRead="(charIndex === -1 ? sampleWord : characters[charIndex])" />

                                <div class="flex items-center justify-between w-full mt-24">

                                <!-- Watch Button -->
                                <button @click="loopCharacterAnimationWithDelay"
                                    class="px-10 py-2 bg-sky-500 text-white font-medium rounded-lg shadow-md hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:ring-opacity-75">
                                    觀看
                                </button>

                                <!-- Toggle Switch -->
                                <label class="inline-flex items-center cursor-pointer">
                                    <input type="checkbox" id="whiteBoardToggle" v-model="whiteBoardMode" class="sr-only peer">
                                    <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    <span class="ms-3 text-sm font-medium text-gray-900">隨意寫</span>
                                </label>
                            </div>

                        </div>

                        <!-- Finger Icon for Hint -->
                        <div v-show="showHint" class="absolute bottom-40 left-1/2 transform -translate-x-1/2">
                            <img src="@/assets/draw.svg" alt="Finger Icon" id="finger-icon" class="w-12 h-12">
                        </div>

                        <!-- Congrats Icons -->
                        <div :class="{ 'z-20': congratsIconsActive, '-z-10': !congratsIconsActive }"
                            class="absolute bottom-1/2 w-4/5 justify-center mt-1 transition-all">
                            <img src="@/assets/100.svg" alt="Icon 1" class="icon w-12 h-12 opacity-0">
                            <img src="@/assets/star.svg" alt="Icon 2" class="icon w-12 h-12 opacity-0">
                            <img src="@/assets/smile.svg" alt="Icon 3" class="icon w-12 h-12 opacity-0">
                            <img src="@/assets/celebration.svg" alt="Icon 4" class="icon w-12 h-12 opacity-0">
                            <img src="@/assets/confetti.svg" alt="Icon 5" class="icon w-12 h-12 opacity-0">
                        </div>
                    </div> 
                </div>
                
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, defineProps, watch, onMounted, nextTick, computed, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import NavBar from './NavBar.vue';
import HanziWriter from 'hanzi-writer';
import AudioButtonsHorizontal from './AudioButtonsHorizontal.vue';
import { fetchVoiceLanguage } from '@/utils/CacheUtil.js';
import { playCantonese, playMandarin } from '@/utils/CommonUtil.js'
import anime from 'animejs/lib/anime.es.js';

const inputText = ref('');

const groups = ref([]);
const currentGroupIndex = ref(0);

const charIndex = ref(-1);
const characters = ref([]);
const errorMessage = ref('');

const isEditing = ref(false);

const voices = ref([]);
const voicesLoaded = ref(false);
const currentAudioText = ref('');

const loadVoices = () => {
  voices.value = speechSynthesis.getVoices();
  if (voices.value.length > 0) {
    voicesLoaded.value = true;
  }
};

// Function to validate input as only Chinese characters and split them
const splitCharacters = () => {
    congratsIconsActive.value = false;

    const chineseRegex = /^[\u4e00-\u9fff]+$/;

    // Split inputText into groups using ',' or '，' as separators
    const inputGroups = inputText.value.trim().split(/[,，]+/);

    // Check the number of groups
    if (inputGroups.length > 3) {
        errorMessage.value = '最多只能輸入 3 組字';
        return;
    }

    const tempGroups = [];

    for (let group of inputGroups) {
        group = group.trim();

        if (!group) continue;

        if (!chineseRegex.test(group)) {
            errorMessage.value = '請輸入正確的中文字';
            return;
        }

        const groupCharacters = Array.from(group);

        if (groupCharacters.length > 4) {
            errorMessage.value = '每組最多只能輸入 4 個中文字';
            return;
        }

        tempGroups.push(groupCharacters);
    }

    if (tempGroups.length === 0) {
        errorMessage.value = '請輸入正確的中文字';
        return;
    }

    errorMessage.value = '';
    groups.value = tempGroups;

    // Default to load the first group
    currentGroupIndex.value = 0;
    characters.value = groups.value[currentGroupIndex.value];
    charIndex.value = 0;
    setWriter(characters.value[0]);
    startQuiz();
};

// Maximum allowed completion count
const MAX_COMPLETION = 3;
const currentUser = ref(null);
const pageLeaving = ref(false)

// Initialize router
const router = useRouter();
const step = ref(0);
const showHint = ref(false);
const congratsIconsActive = ref(false);
let writer;

const screenWidth = ref(window.innerWidth);
const screenHeight = ref(window.innerHeight);

const voiceLanguage = ref('cantonese'); // Default to Cantonese

// Initialize Audio Objects
const audio = new Audio();
const audioMandarin = new Audio();

const sampleWord = ref('例')

const whiteBoardMode = ref(false);
// Canvas-related refs and variables
const canvasRef = ref(null);
let ctx = null;
let drawing = false;


const WRITER_SIZE_IN_PX = computed(() => {
    const minDimension = Math.min(screenWidth.value, screenHeight.value);

    if (minDimension <= 768) { // iPad or smaller screens
        return minDimension * 0.8;
    } else { // Larger screens like MacBook or desktop
        return minDimension * 0.6;
    }
});

// Navigation function
const goHome = () => {
    router.push({ name: 'Home2' });
};

const playDefaultAudio = () => {
    const wordText = (charIndex.value === -1) ? sampleWord.value : characters.value[charIndex.value];
    currentAudioText.value = wordText;

    if (voiceLanguage.value === 'mandarin') {
        playMandarin(currentAudioText.value);
    } else {
        playCantonese(currentAudioText.value);
    }
}


const setSampleWriter = () => {
    if (!writer) {
        writer = HanziWriter.create(
            'character-target-div',
            sampleWord.value,
            {
                width: WRITER_SIZE_IN_PX.value,
                height: WRITER_SIZE_IN_PX.value,
                padding: 10,
                showOutline: false,
                showHintAfterMisses: 3,
                strokeAnimationSpeed: 0.8,
                delayBetweenStrokes: 200,
                delayBetweenLoops: 1000,
                radicalColor: '#337ab7',
                strokeColor: '#555555'
            }
        );
        writer.loopCharacterAnimation();
    } else {
        setWriter(sampleWord.value)
        writer.loopCharacterAnimation();
    }
}

const setWriter = (theWord) => {
    writer.setCharacter(theWord)
}

const reset = () => {
    inputText.value = '';
    errorMessage.value = ''
    charIndex.value = -1;
    characters.value = [];
    setWriter(sampleWord.value)
}

const startQuiz = () => {
    playDefaultAudio()
    writer.showOutline()
    writer.quiz({
        onMistake: function (strokeData) {
            showHint.value = false;
        },
        onCorrectStroke: function (strokeData) {
            showHint.value = false;
        },
        onComplete: function () {
            setTimeout(() => {
                // completed
                if (charIndex.value + 1 === characters.value.length) {
                    animateCongratsIcons();
                }
                // to next character
                else {
                    charIndex.value++;
                    setWriter(characters.value[charIndex.value])
                    startQuiz()
                }
            }, 500);
        }
    });
}

// Define the selectCharacter method
const selectCharacter = (index) => {
    // Prevent selecting the same character
    if (index === charIndex.value) return;

    // Update the current character index
    charIndex.value = index;

    // Set the writer to the selected character
    setWriter(characters.value[index]);

    // Restart the quiz for the selected character
    startQuiz();
};

const animateCongratsIcons = () => {
    congratsIconsActive.value = true;

    const icons = document.querySelectorAll('.icon');
    const selectedIcons = Array.from(icons)
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.floor(Math.random() * 3) + 2);

    anime({
        targets: selectedIcons,
        translateY: function() { return anime.random(-70, -210); },
        translateX: function() { return anime.random(-70, 70); },
        opacity: [0, 1],
        duration: 700,
        easing: 'easeOutQuad',
        delay: anime.stagger(150),
        complete: function() {
            anime({
                targets: selectedIcons,
                translateY: 0,
                opacity: [1, 0],
                duration: 700,
                easing: 'easeOutBounce',
                delay: anime.stagger(150),
                complete: function() {
                    congratsIconsActive.value = false;
                    if (charIndex.value + 1 === characters.value.length) {
                        if (currentGroupIndex.value + 1 < groups.value.length) {
                            selectGroup(currentGroupIndex.value + 1);
                        }
                    }
                }
            });
        }
    });
};

// onMounted
onMounted(async () => {

    voiceLanguage.value = await fetchVoiceLanguage();
    loadVoices();

    // Handle voices loading
    speechSynthesis.addEventListener('voiceschanged', loadVoices, { once: true });

    screenWidth.value = window.innerWidth;
    window.addEventListener('resize', () => {
        screenWidth.value = window.innerWidth;
    });

    voiceLanguage.value = await fetchVoiceLanguage();

    const fingerIcon = document.getElementById('finger-icon');

    anime({
        targets: fingerIcon,
        translateX: [-15, 15, 0, -15, 15, 0],
        translateY: [-20, -20, -30, 0, 0, -20],
        duration: 4000,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
    });

    const writingArea = document.getElementById('writeTheWord');
    if (writingArea) {
        writingArea.addEventListener(
            'touchmove',
            function (event) {
                event.preventDefault();
            },
            { passive: false }
        );
    }

    setSampleWriter()
    // initializeCanvas()
});

// Function to stop audio playback
const stopAllAudio = () => {
    if (audio) {
        audio.pause();
        audio.currentTime = 0; // Reset the audio to the start
    }
    if (audioMandarin) {
        audioMandarin.pause();
        audioMandarin.currentTime = 0; // Reset the audio to the start
    }
};

const selectGroup = (groupIndex) => {
    if (groupIndex === currentGroupIndex.value) return;

    currentGroupIndex.value = groupIndex;
    characters.value = groups.value[currentGroupIndex.value];
    charIndex.value = 0;
    setWriter(characters.value[charIndex.value]);
    startQuiz();
};

// Add onBeforeUnmount lifecycle hook to stop the audio when the component is destroyed
onBeforeUnmount(() => {
    stopAllAudio();
    destroyCanvas();
    pageLeaving.value = true;
});


const keepWriterAsBackground = () => {
    if (writer) {
        writer.showCharacter()
        writer.showOutline()
        writer.pauseAnimation()
        writer.cancelQuiz()
        writer.updateColor('strokeColor', '#CCCCCC')
        writer.updateColor('radicalColor', '#CCCCCC')
    }
};

const loopCharacterAnimationWithDelay = () => {
    if (!writer) return; // Ensure writer is initialized

    writer.animateCharacter({
        onComplete: () => {
            startQuiz()
        }
    });
};


// Function to enable Hanzi Writer
const enableHanziWriter = () => {
    if (writer) {


        writer.updateColor('strokeColor', '#555555')
        writer.updateColor('radicalColor', '#337ab7')

        setTimeout(() => {
            startQuiz()
        }, 1000)

    }
};

const initializeCanvas = () => {
    nextTick(() => {
        const canvas = canvasRef.value;
        if (canvas) {
            ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.strokeStyle = '#333333';
                ctx.lineWidth = 6;
                ctx.lineCap = 'round';
                ctx.lineJoin = 'round';

                // Handle mouse events
                canvas.addEventListener('mousedown', startDrawing);
                canvas.addEventListener('mousemove', draw);
                canvas.addEventListener('mouseup', stopDrawing);
                canvas.addEventListener('mouseout', stopDrawing);

                // Handle touch events for mobile
                canvas.addEventListener('touchstart', startDrawingTouch, { passive: false });
                canvas.addEventListener('touchmove', drawTouch, { passive: false });
                canvas.addEventListener('touchend', stopDrawing);
            } else {
                console.error('Failed to get 2D context from canvas.');
            }
        }
    });
};


// Destroy Canvas
const destroyCanvas = () => {
    const canvas = canvasRef.value;
    if (canvas && ctx) {
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Remove event listeners
        canvas.removeEventListener('mousedown', startDrawing);
        canvas.removeEventListener('mousemove', draw);
        canvas.removeEventListener('mouseup', stopDrawing);
        canvas.removeEventListener('mouseout', stopDrawing);

        canvas.removeEventListener('touchstart', startDrawingTouch);
        canvas.removeEventListener('touchmove', drawTouch);
        canvas.removeEventListener('touchend', stopDrawing);
    }
};

// Drawing functions
const startDrawing = (e) => {
    drawing = true;
    ctx.beginPath();
    ctx.moveTo(e.offsetX, e.offsetY);
};

const draw = (e) => {
    if (!drawing) return;
    ctx.lineTo(e.offsetX, e.offsetY);
    ctx.stroke();
};

const stopDrawing = () => {
    if (!drawing) return;
    drawing = false;
    ctx.closePath();
};

const startDrawingTouch = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const rect = canvasRef.value.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    drawing = true;
    ctx.beginPath();
    ctx.moveTo(x, y);
};

const drawTouch = (e) => {
    e.preventDefault();
    if (!drawing) return;
    const touch = e.touches[0];
    const rect = canvasRef.value.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    ctx.lineTo(x, y);
    ctx.stroke();
};



// Watcher to handle mode switching
watch(whiteBoardMode, (newVal) => {
    if (newVal) {
        // Enable White Board Mode
        keepWriterAsBackground();
        initializeCanvas();
    } else {
        // Disable White Board Mode
        destroyCanvas();
        enableHanziWriter();
    }
});

</script>

<style scoped>
@import "tailwindcss/tailwind.css";

/* Ensure the SVG container is responsive */
svg {
    width: 100%;
    height: auto;
}

.icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
}



</style>
