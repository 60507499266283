<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 64 64">
    <path fill="#1ba9b6" stroke="white" stroke-width="3"
      d="M56.31 1.21C51.993-.922 38.843-.59 35.15 5.098c-.119.188 15.495 8.939 15.515 8.804c.539-4.307.701-7.546 5.342-9.04c2.624-.842 2.472-2.585.306-3.656">
    </path>
    <path fill="#34bdca"  stroke="white" stroke-width="3"
      d="M49.892 11.05c-3.759-3.347-15.03-7.724-22.21-7.635c-1.666.033-3.31.177-4.908.45c-2.713.464-5.02 1.501-6.969 2.944c-.164.097-.334.187-.497.292c-4.766 3.06-7.291 7.269-7.408 12.143c-.074.249-.213.76-.215.767c-1.963 1.244-8.859 6.592-7.516 9.835c.471 1.144 6.611.469 7.637.223c1.804-.436 3.19-.77 4.501-.665s3.894.841 5.569 1.017c7.135.76 13.894.094 19.967 3.03c3.02 1.461 3.786 5.888 3.22 8.863c-.938 2.842-2.674 5.27-5.894 6.454c-1.875.69-3.774.531-5.542-.102c-2.352-2.832-5.104-5.07-8.536-6.783c-2.733-1.364-2.432 5.71 3.749 10.04c.107.074.182.16.271.242c.153 1.731-1.544 3.714-2.099 5.188c-.688 1.838-1.138 6.92.189 6.466c2.821-.963 4.957-2.889 6.332-5.342c.875-.621 1.481-3.288 2.482-3.681c12.983-5.117 18.202-6.501 22.999-15.649c5.069-9.664 2.885-20.975-5.125-28.1">
    </path>
    <path fill="#95d6dc"  stroke="white" stroke-width="3"
      d="M41.07 42.31c-.938 2.842-2.674 5.27-5.894 6.454c-1.325.484-2.658.543-3.951.318c4.406 3.177 10.919 2.152 13.3-3.277c1.597-3.647 1.106-9.381-1.859-12.386c-3.604-3.655-9.22-4.263-14.1-4.173c-4.089.076-8.384.178-12.449.874c.629.127 1.243.239 1.767.293c7.135.76 13.894.094 19.967 3.03c3.02 1.462 3.787 5.889 3.22 8.864">
    </path>
    <path fill="#1ba9b6"  stroke="white" stroke-width="3"
      d="M36.02 29.436c-1-5.109-7.612-5.896-11.232-3.796c-.752.434-1.857 6.37 1.611 9.898c1.524 1.552 3.053 2.89 4.82 4.06l.038.038c1.216 1.07 3.351 2.885 4.594.932c.811-1.269.592-3.483.629-4.898c.06-2.059-.067-4.209-.46-6.234">
    </path>
    <path fill="#34bdca"  stroke="white" stroke-width="3"
      d="M36.05 31.22c-.937-4.832-7.1-5.577-10.476-3.591c-.702.415-1.736 6.03 1.5 9.365c1.423 1.469 2.854 2.733 4.501 3.842c.001.012.018.023.029.033c1.136 1.012 3.128 2.729 4.287.881c.754-1.198.551-3.293.586-4.633c.061-1.948-.062-3.979-.427-5.897">
    </path>
    <path fill="#25333a" 
      d="M18.744 16.728c-2.334 0-4.218 1.755-4.218 3.913c0 2.165 1.884 3.914 4.218 3.914c2.336 0 4.222-1.75 4.222-3.914c0-2.158-1.886-3.913-4.222-3.913">
    </path>
    <ellipse cx="20.515" cy="20.328" fill="#fff" rx="1.857" ry="1.801"></ellipse>
  </svg>
</template>

<script>
export default {
  name: 'EmojioneV1Dolphin'
}
</script>