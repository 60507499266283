<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100">
    <div class="max-w-md w-full bg-white rounded-lg shadow-lg p-8">

      <!-- Header with Back Arrow and Title -->
      <div class="flex items-center mb-6">
        <router-link to="/"
          class="flex items-center text-gray-600 transition duration-200 transform hover:-translate-x-1">
          <ArrowLeftIcon class="h-6 w-6" />
        </router-link>
        <h2 class="text-2xl font-semibold text-center flex-grow">登入</h2>
      </div>

      <img src="/dolphin.jpg" alt="Logo" class="mx-auto mb-6 w-12 h-12">

      <!-- Initial Email Entry -->

      <div v-if="step === 1">
        <h2 class="text-2xl font-semibold text-center mb-6">歡迎</h2>
        <form @submit.prevent="enterEmail">
          <input v-model="email" type="email" placeholder="Email*"
            class="w-full p-2 border rounded mb-4 focus:ring-2 focus:ring-green-400 focus:outline-none transition duration-200 ease-in-out"
            required ref="emailInput" :disabled="loading">
          <button type="submit"
            class="w-full bg-green-500 text-white p-2 rounded transform transition duration-200 hover:scale-105 active:scale-95 shadow-lg hover:shadow-xl"
            :class="{ 'opacity-50': loading }" :disabled="loading">
            繼續
          </button>
        </form>
        <p class="text-center mt-4 text-sm">
          沒有帳戶？
          <router-link to="/signup" class="text-blue-500">登記</router-link>
        </p>
        <div class="flex items-center my-4">
          <hr class="flex-grow border-t border-gray-300">
          <span class="mx-4 text-gray-500">或</span>
          <hr class="flex-grow border-t border-gray-300">
        </div>
        <button @click="loginWithGoogle"
          class="w-full bg-white border border-gray-300 p-2 rounded flex items-center justify-center mb-2 transform transition duration-200 hover:bg-gray-100 hover:scale-105 hover:shadow-md"
          :disabled="loading" :class="{ 'opacity-50': loading }">
          <img src="@/assets/google-icon.svg" alt="Google" class="w-8 h-8 mr-2"> 以 Google 直接登入
        </button>
      </div>


      <!-- Password Entry -->

      <div v-if="step === 2">
        <h2 class="text-2xl font-semibold text-center mb-6">輸入密碼</h2>
        <div class="mb-4">
          <p class="text-center">{{ email }} <button @click="editEmail" class="text-blue-500 text-sm ml-2"
              :disabled="loading">修改</button></p>
        </div>
        <form @submit.prevent="login">
          <input v-model="password" type="password" placeholder="密碼*" class="w-full p-2 border rounded mb-4" required
            ref="passwordInput" @keydown.enter="login" :disabled="loading">
          <!-- Display the error message if login fails -->
          <p v-if="errorMessage" class="text-red-500 text-sm mb-4 transition-opacity duration-500 ease-in-out"
            :class="{ 'opacity-0': !errorMessage, 'opacity-100': errorMessage }">
            {{ errorMessage }}
          </p>


          <p class="text-right text-sm mb-4">
            <router-link to="/forgot" class="text-blue-500">忘記密碼？</router-link>
          </p>
          <button type="submit"
            class="w-full bg-green-500 text-white p-2 rounded transform transition duration-200 hover:scale-105 active:scale-95 shadow-lg hover:shadow-xl"
            :class="{ 'opacity-50': loading }" :disabled="loading">
            <span v-if="loading" class="flex justify-center items-center">
              <svg class="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
            </span>
            <span v-if="!loading">登入</span>
          </button>
        </form>
        <p class="text-center mt-4 text-sm">
          沒有帳戶？
          <router-link to="/signup" class="text-blue-500">登記</router-link>
        </p>
      </div>

    </div>

    <div class="mt-8">
      <router-link to="/terms" class="text-xs text-gray-500">Terms of Use</router-link>
      <span class="mx-2 text-gray-500">|</span>
      <router-link to="/privacy" class="text-xs text-gray-500">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { firebaseAuth } from "../firebase/firebase";
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';

// Reactive state variables
const email = ref('');
const password = ref('');
const step = ref(1); // Track the current step in the login process
const errorMessage = ref('');
const loading = ref(false); // Track loading state

const router = useRouter();

const emailInput = ref(null);
const passwordInput = ref(null);

onMounted(() => {
  emailInput.value.focus(); // Highlight and focus the email input initially
});

// Methods
const enterEmail = () => {
  if (email.value) {
    step.value = 2; // Move to the password entry step
    nextTick(() => {
      passwordInput.value.focus(); // Highlight and focus the password input
    });
  }
};

const editEmail = () => {
  step.value = 1; // Go back to the email entry step
  password.value = ''; // Clear the password field
  nextTick(() => {
    emailInput.value.focus(); // Highlight and focus the email input
  });
};

const login = async () => {
  try {
    loading.value = true; // Start loading
    errorMessage.value = ''; // Clear any previous error message
    await signInWithEmailAndPassword(firebaseAuth, email.value, password.value);
    // Handle successful login (e.g., redirect to account page)
    router.replace({ name: 'Home' });
  } catch (error) {
    console.error("Login failed", error);
    errorMessage.value = '電子郵件或密碼無效。請再試一次。'; // Set the error message
  } finally {
    loading.value = false; // Stop loading
  }
};

const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    loading.value = true; // Start loading
    await signInWithPopup(firebaseAuth, provider);
    router.replace({ name: 'Home' });
  } catch (error) {
    console.error("Google login failed", error);
  } finally {
    loading.value = false; // Stop loading
  }
};
</script>

<!-- <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
} -->
<!-- </style> -->