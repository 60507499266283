// src/utils/CommonUtil.js
import { PLAN_DETAILS } from '@/constants/plan';

export const getSubscribedPlan = (subscription) => {
  if (!subscription || !subscription.items || subscription.items.length === 0) {
    return 'none';
  }

  const item = subscription.items[0];
  const productId = item.plan.product;

  return PLAN_DETAILS[productId] || 'unknown';
};


// /utils/CommonUtils.js
let voicesLoaded = false;
let voices = [];

function loadVoices() {
  voices = speechSynthesis.getVoices();
  if (voices.length > 0) {
    voicesLoaded = true;
  }
}

function setupVoicesListener() {
  if (!voicesLoaded) {
    speechSynthesis.addEventListener('voiceschanged', () => {
      loadVoices();
    }, { once: true });
  }
}

export function playCantonese(text) {
  if (!text) return;
  if (!voicesLoaded) {
    loadVoices();
    setupVoicesListener();
  }

  const utterance = new SpeechSynthesisUtterance(text);
  const cantoneseLangs = ['yue_HK_#Hant', 'zh_HK_#Hant', 'zh-HK'];
  const cantoneseVoice = voices.find(voice =>
    cantoneseLangs.some(lang => voice.lang.startsWith(lang))
  );

  if (cantoneseVoice) {
    utterance.voice = cantoneseVoice;
    utterance.lang = cantoneseVoice.lang;
  } else {
    console.warn('Cantonese voice not found. Using default voice.');
    utterance.lang = 'zh-CN'; // fallback to Mandarin
  }
  utterance.rate = 0.3;
  speechSynthesis.speak(utterance);
}

export function playMandarin(text) {
  if (!text) return;
  if (!voicesLoaded) {
    loadVoices();
    setupVoicesListener();
  }

  const utterance = new SpeechSynthesisUtterance(text);
  const mandarinVoice = voices.find(voice => voice.lang === 'zh-CN');

  if (mandarinVoice) {
    utterance.voice = mandarinVoice;
    utterance.lang = mandarinVoice.lang;
  } else {
    console.warn('Mandarin voice not found. Using default voice.');
    utterance.lang = 'zh-CN';
  }
  utterance.rate = 0.3;
  speechSynthesis.speak(utterance);
}
