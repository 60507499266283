<template>
  <div class="bg-color-blur bg-left-bottom">

    <!-- HomeNavBar Component -->
    <!-- <HomeNavBar :currentUser="currentUser" @logout="handleLogout" /> -->

    <NavBar @back-clicked="goHome" :title="'小海豚中文工作室'" />
    
    <!-- Tabs Navigation -->
     <div >
      <div class="divide-y divide-slate-100">
        <nav class="py-4 px-6 text-sm font-medium flex justify-center items-center">
          <ul class="flex space-x-3">
            <li>
              <a @click="createRipple($event, 'breakdowns')"
                class="flex items-center space-x-4 px-4 sm:px-4 text-xl sm:text-2xl py-2 rounded-md relative overflow-hidden"
                :class="activeTab === 'breakdowns' ? 'bg-sky-500 text-white' : 'bg-slate-50 text-black cursor-pointer'">
                <RiDashboardHorizontalFill />
                結構
              </a>
            </li>
            <li>
              <a @click="createRipple($event, 'radicals')"
                class="flex items-center space-x-4 px-4 sm:px-5 text-xl sm:text-2xl py-2 rounded-md relative overflow-hidden"
                :class="activeTab === 'radicals' ? 'bg-sky-500 text-white' : 'bg-slate-50 text-black cursor-pointer'">
                <FluentStarLineHorizontal320Filled />
                部首
              </a>
            </li>
            <!-- <li>
              <a @click="createRipple($event, 'words')"
                class="flex items-center space-x-2 px-2 sm:px-5 text-xl sm:text-2xl py-2 rounded-md relative overflow-hidden"
                :class="activeTab === 'words' ? 'bg-sky-500 text-white' : 'bg-slate-50 text-black cursor-pointer'">
                <FluentShapeUnion24Regular />
                字詞
              </a>
            </li> -->
          </ul>
        </nav>
      </div>

      <!-- Skeleton Loading -->
      <div v-if="loading" class="max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        <SkeletonCard v-for="n in 6" :key="n" />
      </div>

      <!-- Actual Content -->
      <div v-else class="max-w-screen-xl mx-auto p-4">

        <transition name="fade-slide" mode="out-in">
          <div v-if="isVisible" ref="contentWrapper" class="content-wrapper" :key="activeTab">
            <!-- Non-Breakdowns Tabs Content -->
            <template v-if="activeTab !== 'breakdowns'">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <router-link v-for="item in filteredContent" :key="item.title" :to="navToLink(item)"
                  class="bg-white rounded-lg shadow-md overflow-hidden transform transition-all duration-300 relative"
                  :class="{ 'opacity-85': !IsAccessible(item), 'hover:shadow-lg hover:scale-105': IsAccessible(item) }">

                  <!-- Editor Choice Star -->
                  <div v-if="item.editorChoice"
                    class="absolute top-2 right-2 z-10 rounded-full p-2 bg-gradient-to-br from-gray-600 via-gray-900 to-gray-600">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-yellow-400" fill="currentColor"
                      viewBox="0 0 24 24">
                      <path
                        d="M12 .587l3.668 7.431 8.2 1.192-5.934 5.782 1.4 8.168L12 18.896l-7.334 3.858 1.4-8.168L.132 9.21l8.2-1.192L12 .587z" />
                    </svg>
                  </div>


                  <div class="relative">
                    <img :src="item.imageSrc" class="w-full h-auto object-cover">
                    <div class="absolute bottom-0 p-3 bg-opacity-95 bg-white w-full flex justify-center items-center">
                      <h2 class="text-3xl font-semibold text-center">{{ item.title }}</h2>
                    </div>
                  </div>

                  <!-- Restricted Overlay -->
                  <div v-if="!IsAccessible(item)"
                    class="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                      stroke="currentColor" class="h-10 w-10">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                    </svg>
                    <span class="text-xl mt-2">{{ getOverlayText(item) }}</span>
                  </div>
                </router-link>
              </div>
            </template>

            <!-- Breakdowns Tab Content with Groups -->
            <template v-else>


              <div class="space-y-6">

                <div v-for="(group, index) in visibleBreakdownGroups" :key="group.title" class="border rounded-lg p-4">
                  <!-- Group Header with Icon -->
                  <button @click="toggleGroup(index)" class="w-full flex justify-between items-center focus:outline-none">
                    <span class="flex items-center space-x-2">
                      <!-- Different Icons for Each Group -->
                      <template v-if="group.title === '常用字'">
                        <span class="text-2xl">💡</span>
                      </template>
                      <template v-if="group.title === '基礎字'">
                        <span class="text-2xl">🧱</span>
                      </template>
                      <template v-if="group.title === '數字字'">
                        <span class="text-2xl">🔢</span>
                      </template>

                      <template v-if="group.title === '顏色字'">
                        <span class="text-2xl">🟡</span>
                      </template>
                      <template v-if="group.title === '五感字'">
                        <span class="text-2xl">❤️‍🔥</span>
                      </template>
                      <template v-if="group.title === '交通字'">
                        <span class="text-2xl">🚗</span>
                      </template>
                      <template v-if="group.title === '運動字'">
                        <span class="text-2xl">🏀</span>
                      </template>
                      <template v-if="group.title === '藝術字'">
                        <span class="text-2xl">🎨</span>
                      </template>
                      <template v-if="group.title === '家庭字'">
                        <span class="text-2xl">👨‍👩‍👧‍👦</span>
                      </template>
                      <template v-if="group.title === '生活字'">
                        <span class="text-2xl">🏡</span>
                      </template>


                      <template v-if="group.title === '時間字'">
                        <span class="text-2xl">🕧</span>
                      </template>
                      <template v-else-if="group.title === '食物字'">
                        <span class="text-2xl">🍗</span>
                      </template>
                      <template v-else-if="group.title === '面試常見字'">
                        <span class="text-2xl">📝</span>
                      </template>

                      <!-- Group Title -->
                      <h3 class="text-xl font-semibold">{{ group.title }}</h3>
                    </span>

                    <!-- Toggle Arrow -->
                    <svg v-if="group.isOpen" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                      viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>

                  <!-- Group Content -->
                  <div :class="group.isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'"
                    class="mt-4 grid grid-cols-4 lg:grid-cols-6 gap-4  transition-all duration-300 ease-in-out">
                    <router-link v-for="word in group.words" :key="word.title" :to="navToLink(word)"
                      class="border rounded-md p-4 flex items-center justify-center hover:bg-slate-100 transition-colors relative"
                      :class="{
                        'opacity-85': !IsAccessible(word),
                        'bg-gray-200': isWordCompleted(word.title)
                      }">

                      <!-- Editor Choice Star -->
                      <div v-if="word.editorChoice"
                        class="absolute -top-2 -right-2 z-10 rounded-full p-1.5 bg-gradient-to-br from-gray-600 via-gray-900 to-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-yellow-400" fill="currentColor"
                          viewBox="0 0 24 24">
                          <path
                            d="M12 .587l3.668 7.431 8.2 1.192-5.934 5.782 1.4 8.168L12 18.896l-7.334 3.858 1.4-8.168L.132 9.21l8.2-1.192L12 .587z" />
                        </svg>
                      </div>
                      <span class="text-2xl font-medium">{{ word.title }}</span>


                      <!-- Blue Tick for Completed Words -->
                      <div v-if="isWordCompleted(word.title)"
                        class="absolute bottom-2 right-0 flex items-center justify-end">
                        <!-- Container for Blue Ticks and Plus Sign -->
                        <div class="flex items-center relative">
                          <!-- Blue Ticks -->
                          <svg v-for="n in Math.min(getWordCompletionCount(word.title), 3)" :key="n"
                            xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-sky-500"
                            :style="{ position: 'absolute', right: `${n * 8}px` }" 
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            >
                            <path fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 5.707 10.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                              clip-rule="evenodd" />
                          </svg>
                          <!-- Plus Sign if count > 3 -->
                          <span v-if="shouldShowPlus(word.title)" class="text-sky-500 font-bold text-lg ml-1">+</span>
                        </div>
                      </div>


                      <!-- Overlay for restricted access -->
                      <div v-if="!IsAccessible(word)"
                        class="absolute inset-0 bg-black bg-opacity-20 flex justify-center items-center text-white">
                        <!-- Lock Icon in Bottom-Right -->
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                          stroke="currentColor" class="h-6 w-6 absolute bottom-2 right-2">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                        </svg>
                      </div>
                    </router-link>
                  </div>
                </div>

                <div class="flex justify-center">
                  <button v-if="visibleGroups < breakdownGroups.length"
                    @click="showMoreGroups"
                    class="mt-4 bg-sky-500 px-4 py-2 rounded-md text-white hover:bg-sky-600 flex items-center space-x-2">
                    <span>更多</span>
                    <!-- Optional: Add an icon for "More" -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                </div>

              </div>
            </template>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<style>
/* Optional: Customize Skeleton Animation if needed */
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
</style>


<script setup>
import { ref, onMounted, watch, computed, onActivated, nextTick, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getCurrentUser, logout, db } from '@/firebase/firebase';
import HomeNavBar from '@/components/HomeNavBar.vue';
import NavBar from '@/components/NavBar.vue';
import SkeletonCard from '@/components/SkeletonCard.vue';
import contentData from '@/contentData';
import {
  getFirestore,
  getDocs,
  where,
  query,
  collection,
} from 'firebase/firestore';
import RiDashboardHorizontalFill from './assets/icon/RiDashboardHorizontalFill.vue';
import FluentShapeUnion24Regular from './assets/icon/FluentShapeUnion24Regular.vue';
import FluentStarLineHorizontal320Filled from './assets/icon/FluentStarLineHorizontal320Filled.vue';
import { fetchCompletedWords, updateCompletedWordsCache, fetchSubscription } from '@/utils/CacheUtil';

// Reactive variables
const currentUser = ref(null);
const completedWords = reactive({});

const subscription = ref(null);
const activeTab = ref('breakdowns');
const isVisible = ref(true);

const allContent = ref([]);
const loading = ref(true);

const visibleGroups = ref(5);

// For swipe detection
let startX = 0;
let endX = 0;

// Route and router
const route = useRoute();
const router = useRouter();

// Caching constants
const CACHE_KEY = 'userSubscription';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

// Breakdown Groups Definition
const breakdownGroups = ref([
  {
    title: '常用字',
    words: [],
    isOpen: true, // Default open
  },
  {
    title: '基礎字',
    words: [],
    isOpen: false,
  },
  {
    title: '食物字',
    words: [],
    isOpen: false, 
  },
  {
    title: '顏色字',
    words: [],
    isOpen: false, 
  },
  {
    title: '五感字',
    words: [],
    isOpen: false, 
  },
  {
    title: '數字字',
    words: [],
    isOpen: false,
  },
  {
    title: '時間字',
    words: [],
    isOpen: false,
  },
  {
    title: '交通字',
    words: [],
    isOpen: false, 
  },
  {
    title: '運動字',
    words: [],
    isOpen: false, 
  },
  {
    title: '藝術字',
    words: [],
    isOpen: false, 
  },
  {
    title: '家庭字',
    words: [],
    isOpen: false, 
  },
  {
    title: '生活字',
    words: [],
    isOpen: false, 
  },
  {
    title: '面試常見字',
    words: [],
    isOpen: false,
  },
]);

const isWordCompleted = (wordTitle) => {
  // Return true if the word has been completed at least once
  return completedWords[wordTitle] && completedWords[wordTitle] > 0;
};


const getWordCompletionCount = (wordTitle) => {
  const count = completedWords[wordTitle] || 0;
  return count >= 3 ? 3 : count;
};

// Helper function to check if there's a plus sign needed
const shouldShowPlus = (wordTitle) => {

  const count = completedWords[wordTitle] || 0;
  return count > 3;
};

// Load user and data on mount
onMounted(async () => {
  currentUser.value = await getCurrentUser();
  subscription.value = await fetchSubscription();

  if (currentUser.value) {
    const fetchedWords = await fetchCompletedWords();
    Object.assign(completedWords, fetchedWords);
  }

  // Set activeTab from query parameter
  const tabFromQuery = route.query.tab;
  if (['words', 'radicals', 'breakdowns'].includes(tabFromQuery)) {
    activeTab.value = tabFromQuery;
  }

  // Load content data
  allContent.value = contentData;

  // Assign words to their respective groups
  assignBreakdownGroups();

  loading.value = false; // Data fetched
});

// Function to assign words to groups
const assignBreakdownGroups = () => {
  breakdownGroups.value.forEach(group => {
    switch (group.title) {
      case '常用字':
        group.words = allContent.value.filter(item => ['你', '我', '上', '下', '左', '右'].includes(item.title));
        break;
      case '食物字':
        group.words = allContent.value.filter(item => ['雞', '豬', '牛', '蛋', '飯', '菜'].includes(item.title));
        break;
      case '面試常見字':
        group.words = allContent.value.filter(item => ['課', '本'].includes(item.title));
        break;
      default:
        group.words = [];
    }
  });
};

// Watchers for route query and activeTab
watch(
  () => route.query.tab,
  (newTab) => {
    if (['words', 'radicals', 'breakdowns'].includes(newTab)) {
      activeTab.value = newTab;
    } else {
      activeTab.value = 'words';
    }
  }
);

watch(
  activeTab,
  (newTab) => {
    router.replace({ query: { ...route.query, tab: newTab } });
  }
);

const setActiveTab = (tab) => {
  // Start by hiding the content (fade out)
  isVisible.value = false;

  // Wait for the fade-out animation to complete
  setTimeout(() => {
    // Switch the tab content after the fade-out
    activeTab.value = tab;

    // Wait for the DOM to update
    nextTick(() => {
      // Show the new content (fade in)
      isVisible.value = true;
    });
  }, 500); // Match this delay to the fade-out duration (500ms)
};

// Accessibility Check
const IsAccessible = (item) => {
  if (!item.loginForAccess && !item.subscriptionForAccess) {
    return true;
  } else if (item.subscriptionForAccess) {
    return currentUser.value && subscription.value;
  } else if (item.loginForAccess) {
    return currentUser.value;
  } else {
    return false;
  }
};

// Navigation Link Determination
const navToLink = (item) => {
  if (IsAccessible(item)) {
    return item.path;
  } else if (!currentUser.value) {
    return '/login';
  } else if (item.subscriptionForAccess && !subscription.value) {
    return '/account';
  } else {
    return '/login';
  }
};

// Overlay Text
const getOverlayText = (item) => {
  if (!currentUser.value && item.loginForAccess) {
    return '登入';
  } else if (currentUser.value && item.subscriptionForAccess && !subscription.value) {
    return '訂閱';
  } else {
    return '';
  }
};

// Filtered Content for non-breakdowns tabs
const filteredContent = computed(() => {
  return allContent.value.filter(item => item.category === activeTab.value);
});

// Toggle Group Open/Closed
const toggleGroup = (index) => {
  breakdownGroups.value[index].isOpen = !breakdownGroups.value[index].isOpen;
};


const createRipple = (event, tab) => {
  const button = event.currentTarget;
  const circle = document.createElement('span');
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
  circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
  circle.classList.add('ripple');

  const ripple = button.getElementsByClassName('ripple')[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);

  setActiveTab(tab);
};


// Computed property to get the currently visible groups
const visibleBreakdownGroups = computed(() => {
  return breakdownGroups.value.slice(0, visibleGroups.value);
});

// Method to show more groups
const showMoreGroups = () => {
  if (visibleGroups.value + 5 <= breakdownGroups.value.length) {
    visibleGroups.value += 5;
  } else {
    visibleGroups.value = breakdownGroups.value.length;
  }
};

// Navigation function
const goHome = () => {
    router.push({ name: 'Home2' });
};

</script>

<style>
/* Optional: Customize Skeleton Animation if needed */
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  transform: scale(0);
  animation: ripple-animation 0.6s linear;
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-slide-enter,
.fade-slide-leave-to

/* .fade-slide-leave-active in <2.1.8 */
  {
  opacity: 0;
  transform: translateY(20px);
  /* Slide effect */
}
</style>
