<template>
  <div class="relative h-full flex flex-col lg:flex-row bg-gray-100">
    <!-- left-side image (shown on top for md and below, on the left for lg and above) -->
    <div class="w-full lg:w-1/3 lg:h-full sm:h-1/2 md:h-1/2 flex flex-col justify-end items-center p-4 bg-yellow-100">
      <img :src="imagePath" class="w-full object-cover h-full" />
      <div class="flex items-center justify-center w-full mt-4">
        <div class="text-xl font-semibold tracking-widest">
          <span :class="{'text-red-500 underline': currentWordIndex === 0}">{{ props.words[0] }}</span>
          <span :class="{'text-red-500 underline': currentWordIndex === 1}">{{ props.words[1] }}</span>
        </div>
        <button @click="playAudio" class="ml-4 p-2 bg-blue-500 text-white rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" class="w-6 h-6">
            <circle cx="12" cy="12" r="10"/>
            <line x1="15" y1="9" x2="9" y2="15" stroke="white" stroke-width="2"/>
            <line x1="9" y1="9" x2="15" y2="15" stroke="white" stroke-width="2"/>
          </svg>
        </button>
      </div>
    </div>

    <!-- right-side word -->
    <div class="flex flex-col justify-center items-center flex-grow">
      <!-- Navigation Bar -->
      <div class="flex justify-center p-6 gap-10">
        <!-- Eye Icon Button -->
        <button 
          :class="{'text-blue-500': currentTab === 'observe', 'text-gray-500': currentTab !== 'observe'}"
          @click="currentTab = 'observe'"
          class="flex flex-col items-center focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" class="w-10 h-10">
            <path d="M12 4.5c-4.1 0-7.7 2.6-9 6.2 1.3 3.6 4.9 6.2 9 6.2s7.7-2.6 9-6.2c-1.3-3.6-4.9-6.2-9-6.2zm0 10.5c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5zm0-6.5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
          </svg>
        </button>

        <!-- Pen Icon Button -->
        <button 
          :class="{'text-blue-500': currentTab === 'strokes', 'text-gray-500': currentTab !== 'strokes'}"
          @click="currentTab = 'strokes'"
          class="flex flex-col items-center focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" class="w-10 h-10">
            <path d="M11.4001 18.1612L11.4001 18.1612L18.796 10.7653C17.7894 10.3464 16.5972 9.6582 15.4697 8.53068C14.342 7.40298 13.6537 6.21058 13.2348 5.2039L5.83882 12.5999L5.83879 12.5999C5.26166 13.1771 4.97307 13.4657 4.7249 13.7838C4.43213 14.1592 4.18114 14.5653 3.97634 14.995C3.80273 15.3593 3.67368 15.7465 3.41556 16.5208L2.05445 20.6042C1.92743 20.9852 2.0266 21.4053 2.31063 21.6894C2.59466 21.9734 3.01478 22.0726 3.39584 21.9456L7.47918 20.5844C8.25351 20.3263 8.6407 20.1973 9.00498 20.0237C9.43469 19.8189 9.84082 19.5679 10.2162 19.2751C10.5343 19.0269 10.823 18.7383 11.4001 18.1612Z"/>
            <path d="M20.8482 8.71306C22.3839 7.17735 22.3839 4.68748 20.8482 3.15178C19.3125 1.61607 16.8226 1.61607 15.2869 3.15178L14.3999 4.03882C14.4121 4.0755 14.4246 4.11268 14.4377 4.15035C14.7628 5.0875 15.3763 6.31601 16.5303 7.47002C17.6843 8.62403 18.9128 9.23749 19.85 9.56262C19.8875 9.57563 19.9245 9.58817 19.961 9.60026L20.8482 8.71306Z" />
          </svg>
        </button>

        <!-- Writing Pen Icon Button -->
        <button 
          :class="{'text-blue-500': currentTab === 'quiz', 'text-gray-500': currentTab !== 'quiz'}"
          @click="currentTab = 'quiz'"
          class="flex flex-col items-center focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" class="w-10 h-10">
            <path class="cls-1" d="M7.24,22.52,2.09,17.38a2,2,0,0,1,1.44-3.47,2,2,0,0,1,1.43.6l1.32,1.32V6.37A2,2,0,0,1,8,4.36a1.83,1.83,0,0,1,1.51.55,1.86,1.86,0,0,1,.57,1.35V12l5.05.72a1.92,1.92,0,0,1,1.64,1.9h0A17.22,17.22,0,0,1,15,22.34l-.09.18"/><polyline class="cls-1" points="10.11 6.26 19.67 6.26 13.94 1.48 23.5 1.48"/>
          </svg>
        </button>
      </div>

      <!-- Tab Content with Clickable Areas -->
      <div class="flex h-full">
        <!-- Left Clickable Area -->
        <div @click="previousTab" class="flex items-center justify-center cursor-pointer" style="flex: 3;">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#d3d3d3" viewBox="0 0 24 24" class="w-10 h-10">
            <path d="M17.59 3.41L16.18 2 8 12l8.18 10 1.41-1.41L10.83 12z"/>
          </svg>
        </div>

        <!-- Tab Content -->
        <div class="flex justify-center items-center" style="flex: 7;">
          <div v-if="currentTab === 'observe'">
            <ObserveWord 
            :word="props.word" 
            :imagePath="props.imagePath" 
            :idSuffix="props.idSuffix" 
            :audioPath="props.audioPath" 
            :option="props.option"
            :radicalOption="props.radicalOption"
            />
          </div>
          <div v-else-if="currentTab === 'strokes'">
            <ReadStrokes :word="props.word" :idSuffix="props.idSuffix" />
          </div>
          <div v-else-if="currentTab === 'quiz'">
            <StartQuiz :word="props.word" :idSuffix="props.idSuffix" />
          </div>
        </div>

        <!-- Right Clickable Area -->
        <div @click="nextTab" class="flex items-center justify-center cursor-pointer" style="flex: 3;">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#d3d3d3" viewBox="0 0 24 24" class="w-10 h-10">
            <path d="M6.41 20.59L7.82 22 16 12 7.82 2 6.41 3.41 13.17 12z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue';
import ObserveWord from './ObserveWord.vue';
import ReadStrokes from './ReadStrokes.vue';
import StartQuiz from './StartQuiz.vue';

// Props
const props = defineProps({
  word: {
    type: String,
    required: true,
  },
  imagePath: {
    type: String,
    required: false
  },
  idSuffix: {
    type: String,
    required: true,
  },
  audioPath: {
    type: String,
    required: false,
  },
  audioPathArray: {
    type: Array,
    required: false,
    default: () => []  // Ensure audioPathArray is always an array
  },
  words: {
    type: Array,
    required: true
  },
  option: {
    type: Object,
    required: false
  },
  radicalOption: {
    type: Object,
    required: false
  }
});

// Reactive state
const currentTab = ref('observe');
const currentWordIndex = ref(null); // Start with no word highlighted
let audio = new Audio();

// Function to play audio
function playAudio() {
  if (props.audioPathArray && props.audioPathArray.length > 0) {
    currentWordIndex.value = 0; // Start highlighting the first word
    playNextAudio(currentWordIndex.value);
  }
}

function playNextAudio(index) {
  if (props.audioPathArray && index < props.audioPathArray.length) {
    audio.src = props.audioPathArray[index];
    audio.play();
    currentWordIndex.value = index;

    audio.onended = () => {
      if (index + 1 < props.audioPathArray.length) {
        playNextAudio(index + 1); // Play the next audio if it exists
      } else {
        currentWordIndex.value = null; // Stop highlighting after the last audio
      }
    };
  }
}

// Function to navigate to the next tab
function nextTab() {
  if (currentTab.value === 'observe') {
    currentTab.value = 'strokes';
  } else if (currentTab.value === 'strokes') {
    currentTab.value = 'quiz';
  }
}

// Function to navigate to the previous tab
function previousTab() {
  if (currentTab.value === 'quiz') {
    currentTab.value = 'strokes';
  } else if (currentTab.value === 'strokes') {
    currentTab.value = 'observe';
  }
}

// Watch for changes in props.word and reset the currentTab to 'observe'
watch(() => props.word, () => {
  currentTab.value = 'observe';
  currentWordIndex.value = null; // No word highlighted initially
  audio.pause();
  audio.currentTime = 0; // Reset audio to start
});
</script>


<style scoped>
/* You can add additional styles here if needed */
</style>
